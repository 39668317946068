import { ResponseMeta } from './ResponseMeta';
import { Role } from './Role';

export interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  email_verified_at?: string;
  created_at: Date;
  updated_at?: Date;
  deleted_at?: Date;
  last_login?: Date | string; // TODO in api
  password?: string; // Used for post
  password_confirmation?: string;

  // TODO: we need more fields here, see below
  roles?: Role[];
  photo_url?: string; // TODO in api
}

export interface UsersResponse {
  success: boolean;
  message: string;
  data?: User[];
  meta?: ResponseMeta;
}

export function getNewUser(): User {
  return {
    id: -1,
    created_at: new Date(),
    updated_at: new Date(),
    roles: [],
    first_name: '',
    last_name: '',
    email: '',
  };
}
