import * as React from 'react';
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  Chip,
  IconButton,
  InputAdornment,
  Link,
  Skeleton,
  TextField,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import {
  PageableTable,
  PageableTableRow,
  PopperDropdownMenu,
  SnackbarContext,
} from 'components';
import { PageableTableHeader } from 'components/PageableTable/components';
import { Search } from '@mui/icons-material';
import { generateSeededColour } from 'lib';
import { UserDrawer } from './UserDrawer';
import { User, UsersResponse } from 'lib/Model/User';
import { deleteUser, fetchUsers } from 'lib/Service/Users';
import { useDebounce } from 'hooks';
import { ConfirmDialog } from 'components/ConfirmDialog';
import { AxiosError } from 'axios';
import styled from '@emotion/styled';
import { format } from 'date-fns';
import { DateTimeFormat } from 'config';

const StyledLink = styled(Link)(
  () => `
    margin-bottom: 0;
    text-transform: none;
    font-weight: 500;
    text-decoration: none;
    font-size: 120%;
    cursor: pointer;
  `,
);

export const UsersTable: React.FC = () => {
  const theme = useTheme();
  const snackbar = React.useContext(SnackbarContext);

  const [textSearch, setTextSearch] = React.useState('');
  const [selectedUser, setSelectedUser] = React.useState<User | undefined>();
  const [toDelete, setToDelete] = React.useState<User | undefined>();

  const debouncedTextSearch = useDebounce(textSearch, 500);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [queryResponse, setQueryResponse] = React.useState<
    UsersResponse | undefined
  >();

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const fetchData = React.useCallback(() => {
    setLoading(true);
    fetchUsers(debouncedTextSearch, pageNumber + 1, pageSize)
      .then((d) => {
        setLoading(false);
        setQueryResponse(d);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [setLoading, setQueryResponse, pageNumber, pageSize, debouncedTextSearch]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = React.useMemo(() => {
    return [
      {
        key: 'avatar',
        label: '',
        sortable: false,
        props: {
          style: { width: 60 },
        },
      },
      {
        key: 'user',
        label: 'User',
        sortable: true,
      },
      {
        key: 'roles',
        label: 'Roles',
        sortable: true,
      },
      {
        key: 'lastLogin',
        label: 'Last login',
        sortable: true,
      },
      {
        key: 'actions',
        label: '',
        sortable: true,
      },
    ] as PageableTableHeader[];
  }, []);

  const [rows, setRows] = React.useState<PageableTableRow[]>([]);

  React.useEffect(
    () =>
      setRows(
        queryResponse?.data?.map((u) => {
          return {
            key: `user_${u.id}`,
            cells: [
              {
                key: 'avatar',
                display: <Avatar src={u.photo_url} />,
              },
              {
                key: 'user',
                display: (
                  <React.Fragment>
                    <StyledLink
                      onClick={() => {
                        setSelectedUser(u);
                      }}
                    >
                      {`${u.first_name} ${u.last_name}`}
                    </StyledLink>
                    <Typography variant="caption" style={{ display: 'block' }}>
                      {u.email}
                    </Typography>
                  </React.Fragment>
                ),
              },
              {
                key: 'roles',
                display: (
                  <React.Fragment>
                    {u.roles?.map((r) => (
                      <Chip
                        variant="outlined"
                        label={r.name}
                        style={{
                          margin: theme.spacing(0.2),
                          borderColor: `#${generateSeededColour({
                            seed: r.name,
                          })}`,
                        }}
                        avatar={
                          <Avatar
                            style={{
                              backgroundColor: `#${generateSeededColour({
                                seed: r.name,
                              })}`,
                            }}
                          >
                            &nbsp;
                          </Avatar>
                        }
                        size="small"
                      />
                    ))}
                  </React.Fragment>
                ),
              },
              {
                key: 'lastLogin',
                display: (
                  <React.Fragment>
                    {u.last_login && Boolean(u.last_login) ? (
                      <Typography>
                        {format(u.last_login as Date, DateTimeFormat.MEDIUM)}
                      </Typography>
                    ) : null}
                  </React.Fragment>
                ),
              },
              {
                key: 'actions',
                props: {
                  style: {
                    textAlign: 'right',
                  },
                },
                display: (
                  <PopperDropdownMenu
                    menuItems={[
                      {
                        label: 'Edit',
                        onClick: () => {
                          setSelectedUser(u);
                        },
                      },
                      {
                        label: 'Revoke access', // TODO: api endpoints for this
                      },
                      {
                        label: 'Delete',
                        onClick: () => {
                          setToDelete(u);
                        },
                      },
                    ]}
                  />
                ),
              },
            ],
          } as PageableTableRow;
        }) ?? [],
      ),
    [queryResponse, setRows, theme],
  );

  return (
    <Card>
      <CardHeader
        title="Users"
        action={
          <Button
            color="primary"
            onClick={() => {
              setSelectedUser(undefined);
              setDrawerOpen(true);
            }}
          >
            Add user
          </Button>
        }
      />
      <Toolbar style={{ marginBottom: theme.spacing(1) }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Type here to find users.."
          value={textSearch}
          onChange={(e) => setTextSearch(e.target.value ?? '')}
          autoFocus
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>

      {loading ? (
        <Skeleton variant="rectangular" height={150} />
      ) : (
        <PageableTable
          columns={columns}
          rows={rows}
          pageNumber={pageNumber}
          rowCount={queryResponse?.meta?.total ?? rows.length}
          onChangePageSize={setPageSize}
          onChangePage={setPageNumber}
        />
      )}

      <UserDrawer
        open={Boolean(selectedUser) || drawerOpen}
        onClose={() => {
          setSelectedUser(undefined);
          setDrawerOpen(false);
        }}
        user={selectedUser}
        onSaved={() => {
          setSelectedUser(undefined);
          setDrawerOpen(false);
          fetchData();
        }}
      />

      {toDelete && (
        <ConfirmDialog
          dialogProps={{
            open: true,
          }}
          typePrompt={`${toDelete?.first_name} ${toDelete?.last_name}`}
          title="Are you sure you want to proceed?"
          yesText="Delete"
          noText="Cancel"
          onCancel={() => setToDelete(undefined)}
          onConfirm={async () => {
            try {
              await deleteUser(toDelete);
              snackbar.success('User deleted');
              fetchData();
            } catch (error) {
              if (error instanceof AxiosError && error.response?.data.message) {
                snackbar.error(error.response.data.message);
                return;
              }
              snackbar.error('Unknown error while saving');
            } finally {
              setToDelete(undefined);
            }
          }}
        />
      )}
    </Card>
  );
};
