import { CssBaseline } from '@mui/material';
import { cyan, orange } from '@mui/material/colors';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import * as React from 'react';

export const AppTheme: React.FC = ({ children }) => {
  // https://material-ui.com/customization/themes/
  const theme = createTheme({
    palette: {
      // mode: 'dark',
      background: {
        default: '#f8fafb',
      },
      primary: {
        main: cyan[700],
      },
      secondary: {
        main: orange[500],
      },
    },
    drawerWidth: 80,
    loadingDelay: '800ms',
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

// https://material-ui.com/guides/typescript/#customization-of-theme
declare module '@mui/system/createTheme' {
  interface Theme {
    drawerWidth: number;
    loadingDelay: string;
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    drawerWidth: number;
    loadingDelay: string;
  }
}
