import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
  TextField,
  Button,
} from '@mui/material';
import { uploadAttachment } from 'lib/Service/Articles';
import { ArticleAttachment } from 'lib/Model/Article';
import { FileUploadButton } from 'components/FileUploadButton';
import { AttachmentType } from 'lib/Helper/Enums';
import { SnackbarContext } from 'components';
import { usePrevious } from 'hooks';
import { getAttachmentIcon } from './ArticleEditor';

interface Props {
  attachment?: ArticleAttachment;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  onSave: (attachment: ArticleAttachment) => void;
  isCover?: boolean;
}
export const AttachmentDialog: React.FC<Props> = ({
  attachment,
  isOpen,
  setIsOpen,
  onSave,
  isCover = false,
}) => {
  const [loadingUpload, setLoadingUpload] = React.useState(false);
  const snackbar = React.useContext(SnackbarContext);
  const [caption, setCaption] = React.useState<string | null>(null);
  const [currentAttachment, setCurrentAttachment] = React.useState<
    ArticleAttachment | undefined
  >(undefined);

  React.useEffect(() => {
    setCurrentAttachment(attachment);
    setCaption(attachment?.caption ?? '');
  }, [attachment, setCurrentAttachment, setCaption]);

  const prevOpen = usePrevious(isOpen);
  React.useEffect(() => {
    if (prevOpen !== isOpen && isOpen) {
      setCaption(attachment?.caption ?? '');
      if (!attachment) {
        setCurrentAttachment(undefined);
      }
    }
  }, [attachment, setCaption, isOpen, prevOpen]);

  return (
    <Dialog maxWidth="sm" fullWidth open={isOpen}>
      <DialogTitle>
        {currentAttachment ? `Update` : 'Create'}{' '}
        {isCover ? 'cover' : 'attachment'}
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            backgroundColor: '#eee',
            minHeight: 100,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 8,
          }}
        >
          {loadingUpload ? (
            <CircularProgress size={48} />
          ) : currentAttachment ? (
            <div>
              {currentAttachment.type === AttachmentType.IMAGE ? (
                <img
                  alt={currentAttachment.name}
                  src={currentAttachment.url}
                  style={{ display: 'block', maxWidth: '100%' }}
                />
              ) : (
                <div
                  style={{
                    flexBasis: 100,
                    backgroundColor: '#eee',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {getAttachmentIcon(currentAttachment.type)}
                  <span>{currentAttachment.name}</span>
                </div>
              )}
              {getUploadAttachmentButton('Change')}{' '}
            </div>
          ) : (
            getUploadAttachmentButton('Upload')
          )}
        </div>
        <TextField
          placeholder="Caption"
          value={caption}
          onChange={(e) => {
            const newCaption = e.target?.value ?? '';
            setCurrentAttachment(
              (ca) =>
                ca && {
                  ...ca,
                  caption: newCaption,
                },
            );
            setCaption(newCaption);
          }}
          fullWidth
          autoFocus
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loadingUpload}
          onClick={() => setIsOpen(false)}
          color="inherit"
        >
          Cancel
        </Button>
        <Button
          disabled={loadingUpload || !currentAttachment}
          color="primary"
          onClick={async () => {
            if (!currentAttachment) {
              return;
            }
            currentAttachment.caption = caption ?? '';
            try {
              setLoadingUpload(true);
              const uploadedFile = await uploadAttachment({
                id: currentAttachment.id,
                caption,
                type: currentAttachment.type,
                isCover: currentAttachment.is_cover,
                // TODO: send cropping info?
              });
              setLoadingUpload(false);
              onSave(currentAttachment);
              setCurrentAttachment(
                uploadedFile
                  ? {
                      ...uploadedFile,
                      is_cover: isCover,
                      caption: caption ?? '',
                    }
                  : undefined,
              );
              setCaption(null);
            } catch (ex) {
              setLoadingUpload(false);
              snackbar.error(ex);
            }
          }}
        >
          Save attachment
        </Button>
      </DialogActions>
    </Dialog>
  );

  function getUploadAttachmentButton(label: string) {
    return (
      <FileUploadButton
        label={label}
        allowedAttachmentTypes={
          isCover
            ? [AttachmentType.IMAGE]
            : [AttachmentType.IMAGE, AttachmentType.OTHER, AttachmentType.AUDIO]
        }
        onSelect={async (files) => {
          try {
            setLoadingUpload(true);
            const uploadedFile = await uploadAttachment({
              id: currentAttachment?.id ?? undefined,
              file: files[0],
              type: AttachmentType.IMAGE, // TODO: support other file upload types
              isCover,
              caption,
            });
            if (uploadedFile) {
              setCurrentAttachment({
                ...uploadedFile,
                is_cover: isCover,
                caption: caption ?? '', // Always use latest caption in case the user changed in the meantime
              });
            }
            setLoadingUpload(false);
          } catch (ex) {
            setLoadingUpload(false);
            snackbar.error(ex);
          }
        }}
      />
    );
  }
};
