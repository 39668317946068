import {
  Button,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useTheme,
  TableContainerProps,
} from '@mui/material';
import { TableProps } from '@mui/material/Table';
import { TableCellProps } from '@mui/material/TableCell';
import { TableHeadProps } from '@mui/material/TableHead';
import { TableRowProps } from '@mui/material/TableRow';
import { TableSortLabelProps } from '@mui/material/TableSortLabel';
import { Skeleton } from '@mui/lab';
import { DelayedLinearProgress } from 'components/DelayedLinearProgress';
import {
  getTableSettingsStorageKey,
  TableSettings,
  useLocalStorageSettings,
  usePrevious,
} from 'hooks';
import * as React from 'react';
import { useLocation } from 'react-router';
import { Pagination } from '.';
import { EmptyView } from '../EmptyView';
import { PageableTableHead, PageableTableHeader } from './components';
export interface PageableTableCell {
  key: string;
  display: React.ReactNode;
  props?: TableCellProps;
}

export interface PageableTableRow {
  key: string;
  cells: PageableTableCell[];
  props?: TableRowProps;
}

interface Props {
  defaultSort?: {
    columnKey: string;
    order: TableSortLabelProps['direction'];
  };
  showSkeletonLoading?: boolean;
  columns: PageableTableHeader[];
  rows: PageableTableRow[];
  rowCount?: number;
  loading?: boolean;
  tableProps?: TableProps;
  tableContainerProps?: TableContainerProps;
  tableHeadProps?: TableHeadProps;
  tableFooter?: JSX.Element;
  emptyTableText?: string;
  disablePagination?: boolean;
  disableSorting?: boolean;
  infiniteScroll?: boolean;
  pageNumber?: number;
  defaultRowsPerPage?: number;
  hideColumns?: boolean;

  emptyTableCreateAction?(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void;
  onChangePage(pageNumber: number): void;
  onChangePageSize?(pageSize: number): void;
}

export const PageableTable: React.FC<Props> = ({
  defaultSort,
  columns,
  rows,
  rowCount,
  loading = false,
  tableProps,
  tableHeadProps,
  tableFooter,
  emptyTableText,
  disablePagination,
  disableSorting,
  pageNumber,
  defaultRowsPerPage,
  hideColumns,
  showSkeletonLoading,
  tableContainerProps,
  emptyTableCreateAction,
  onChangePage,
  onChangePageSize,
}) => {
  const { pathname, hash } = useLocation();
  const theme = useTheme();

  const [tableSettings, setTableSettings] =
    useLocalStorageSettings<TableSettings>(
      getTableSettingsStorageKey(pathname, hash),
      { rowsPerPage: defaultRowsPerPage ?? 10 },
    );

  let sortableColumn;

  if (tableSettings.orderBy) {
    sortableColumn = columns.find((c) => c.key === tableSettings.orderBy);
  } else if (defaultSort) {
    const { columnKey } = defaultSort;

    sortableColumn = columns.find((c) => c.key === columnKey);
  } else {
    sortableColumn = columns.find(
      (c) => c.sortable === undefined || c.sortable,
    );
  }

  const totalRows = rowCount ?? rows.length;

  const [order, setOrder] = React.useState<TableSortLabelProps['direction']>(
    tableSettings.order
      ? tableSettings.order
      : defaultSort
      ? defaultSort.order
      : 'asc',
  );
  const [orderBy, setOrderBy] = React.useState(
    sortableColumn && sortableColumn.key,
  );
  const [page, setPage] = React.useState(pageNumber ?? 0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    tableSettings.rowsPerPage,
  );

  const prevTotalRows = usePrevious(totalRows);
  React.useEffect(() => {
    const maxPage = Math.ceil(totalRows / rowsPerPage);
    // the counter for page starts from 0, and maxPage starts from 1
    if (
      (page >= maxPage || (prevTotalRows && prevTotalRows !== totalRows)) &&
      !loading
    ) {
      setPage(0);
    }
  }, [rowsPerPage, page, totalRows, prevTotalRows, loading]);

  const prevPage = usePrevious(page);
  React.useEffect(() => {
    if (prevPage !== page) {
      onChangePage(page);
    }
  }, [onChangePage, page, prevPage]);

  const prevRowsPerPage = usePrevious(rowsPerPage);
  React.useEffect(() => {
    if (prevRowsPerPage !== rowsPerPage && onChangePageSize) {
      onChangePageSize(rowsPerPage);
    }
  }, [onChangePageSize, rowsPerPage, prevRowsPerPage]);

  let rowCopy = rows;

  if (!disablePagination && typeof rowCount === 'undefined') {
    // Paging
    rowCopy = rowCopy.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
  }

  if (loading && showSkeletonLoading) {
    return <Skeleton variant="rectangular" style={{ height: 450 }} />;
  }

  return (
    <React.Fragment>
      {!showSkeletonLoading ? (
        <DelayedLinearProgress loading={loading} />
      ) : null}

      {totalRows ? (
        <React.Fragment>
          <TableContainer {...tableContainerProps}>
            <Table stickyHeader aria-label="sticky table" {...tableProps}>
              {!hideColumns && (
                <PageableTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  columnData={columns}
                  disableSorting={disableSorting}
                  tableHeadProps={tableHeadProps}
                />
              )}

              <TableBody>
                {rowCopy.map((row, index) => (
                  <TableRow hover tabIndex={-1} key={row.key} {...row.props}>
                    {row.cells.map(({ display, key: cellKey, props }) => (
                      <TableCell key={cellKey} {...props}>
                        {display}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {!disablePagination && (
            <Pagination
              standalone
              totalRows={totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}

          {tableFooter && (
            <div style={{ padding: theme.spacing(2) }}>{tableFooter}</div>
          )}
        </React.Fragment>
      ) : (
        !loading && (
          <EmptyView>
            {emptyTableText ?? 'Nothing here yet'}

            <br />

            {emptyTableCreateAction && (
              <Button
                color="primary"
                size="small"
                onClick={emptyTableCreateAction}
              >
                Create one
              </Button>
            )}
          </EmptyView>
        )
      )}
    </React.Fragment>
  );

  function handleRequestSort(
    e: React.MouseEvent<HTMLElement>,
    newOrderBy: string,
  ) {
    if (disableSorting) {
      return;
    }

    const newOrder =
      orderBy === newOrderBy && order === 'desc' ? 'asc' : 'desc';

    setOrder(newOrder);
    setOrderBy(newOrderBy);

    setTableSettings((s) => ({ ...s, order: newOrder, orderBy: newOrderBy }));
  }

  function handleChangePage(
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) {
    if (disablePagination) {
      return;
    }

    window.scrollTo(0, 0);
    setPage(newPage);
  }

  function handleChangeRowsPerPage({
    target: { value },
  }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setTableSettings((s) => ({ ...s, rowsPerPage: parseInt(value, 10) }));
  }
};
