import { green, grey, orange, red } from '@mui/material/colors';

export enum ArticleStatus {
  DRAFT = 1,
  REVIEW = 2,
  APPROVED = 3,
  PUBLISHED = 4,
  DELETED = 5,
  HOLD = 6,
}

export enum AttachmentType {
  IMAGE = 1,
  VIDEO = 2,
  AUDIO = 3,
  OTHER = 4,
}

export function attachmentTypeToFriendly(type: AttachmentType) {
  switch (type) {
    case AttachmentType.IMAGE:
      return 'image';
    case AttachmentType.VIDEO:
      return 'video';
    case AttachmentType.AUDIO:
      return 'audio';
    case AttachmentType.OTHER:
      return 'other';
  }
}

export function articleStatusToFriendly(
  articleStatus?: ArticleStatus | string | null,
) {
  if (!articleStatus) {
    return 'N/A';
  }
  switch (articleStatus) {
    case ArticleStatus.DRAFT:
      return 'Draft';
    case ArticleStatus.REVIEW:
      return 'Review';
    case ArticleStatus.APPROVED:
      return 'Approved';
    case ArticleStatus.PUBLISHED:
      return 'Published';
    case ArticleStatus.DELETED:
      return 'Deleted';
    case ArticleStatus.HOLD:
      return 'Hold';
    default:
      return 'N/A';
  }
}

export function articleStatusToColor(
  articleStatus?: ArticleStatus | string | null,
) {
  if (!articleStatus) {
    return undefined;
  }
  switch (articleStatus) {
    case ArticleStatus.DRAFT:
      return grey[400];
    case ArticleStatus.REVIEW:
      return orange[300];
    case ArticleStatus.APPROVED:
      return green[400];
    case ArticleStatus.PUBLISHED:
      return green[700];
    case ArticleStatus.DELETED:
      return red[400];
    case ArticleStatus.HOLD:
      return red[300];
    default:
      return undefined;
  }
}
