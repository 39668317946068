import Cookies from 'js-cookie';
import { getApiClient } from './Api';
import { setGlobalState } from 'App.state';

interface UserData {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  email_verified_at: Date | null;
}

export function getCsrfCookie() {
  return getApiClient().get(`/sanctum/csrf-cookie`);
}

export async function logout() {
  return getApiClient()
    .get(`/api/logout`)
    .finally(() => {
      clearAuthData();
      document.location.hash = 'logout';
    });
}

export function clearAuthData() {
  setGlobalState('authUser', null);
  Cookies.remove('authUser');
}

export async function login(email: String, password: String) {
  await getCsrfCookie();
  return getApiClient()
    .post(`/api/login`, {
      email,
      password,
    })
    .then((response) => {
      if (response.data.user) {
        setGlobalState('authUser', response.data.user);
        setUserData(response.data.user);
      }
      return response.data.user;
    });
}

export async function forgotPassword(email: String, link: String) {
  await getCsrfCookie();
  return getApiClient()
    .post(`/api/forgot-password`, {
      email,
      link,
    })
    .then((response) => {
      return true;
    });
}

export async function resetPassword(
  email: String,
  password: String,
  repeatPassword: String,
  code: String,
) {
  await getCsrfCookie();
  return getApiClient()
    .post(`/api/reset-password/${code}`, {
      email,
      password,
      password_confirmation: repeatPassword,
    })
    .then((response) => {
      return true;
    });
}

function setUserData(userData: any) {
  Cookies.set('authUser', JSON.stringify(userData));
}

export function getUserData() {
  const user = Cookies.get('authUser');
  if (!user) {
    return null;
  }

  try {
    const userData = JSON.parse(user) as UserData;
    if (!userData.email) {
      return null;
    }
    return userData;
  } catch (error) {
    return null;
  }
}

export function configureApiErrorHandler() {
  getApiClient().interceptors.response.use(
    (response) => {
      return response;
    },
    (error: any) => {
      if (error.response?.data?.detail) {
        return Promise.reject(new Error(error.response.data.detail));
      }
      return Promise.reject(error);
    },
  );
}
