import { bbcodeTag } from '../helpers/bbcode_tag_creator';
import tinymce from 'tinymce';

export const CustomPluginFacebook = () => {
  tinymce.PluginManager.add('tomFacebook', (editor, url) => {
    const openFacebook = () => {
      return editor.windowManager.open({
        title: 'Embed Facebook Post / Video',
        body: {
          type: 'panel',
          items: [
            {
              type: 'listbox',
              name: 'embed_type',
              label: 'Embed Type:',
              items: [
                { text: 'Post', value: 'post' },
                { text: 'Video', value: 'video' },
              ],
            },
            {
              type: 'listbox',
              name: 'show_text',
              label: 'Include text from photo post:',
              items: [
                { text: 'Yes', value: 'true' },
                { text: 'No', value: 'false' },
              ],
            },
            {
              type: 'input',
              name: 'url',
              label: 'URL:',
              placeholder:
                'https://www.facebook.com/timesofmalta/videos/2359651940799834/',
            },
          ],
        },
        buttons: [
          {
            type: 'cancel',
            text: 'Close',
          },
          {
            type: 'submit',
            text: 'Save',
            primary: true,
          },
        ],
        onSubmit: (api) => {
          // ensure https protocol:
          // ensure hotname includes facebook in url
          // ensure not empty
          const { url, embed_type, show_text } = api.getData();

          try {
            const trimmedUrl = new URL(url.trim());

            if (
              trimmedUrl.protocol === 'https:' &&
              trimmedUrl.hostname.includes('facebook.com') &&
              trimmedUrl.href
            ) {
              editor.insertContent(
                bbcodeTag('facebook', '', {
                  embed_type: embed_type,
                  url: trimmedUrl,
                  show_text: show_text,
                }),
              );
              api.close();
            } else {
              tinymce.activeEditor?.windowManager.alert('Invalid facebook URL');
            }
          } catch (ex) {
            tinymce.activeEditor?.windowManager.alert('Invalid URL');
          }
        },
      });
    };

    editor.ui.registry.addButton('tomFacebook', {
      icon: 'facebook',
      tooltip: 'Embed Facebook Post / Video',
      onAction: () => openFacebook(),
    });
  });
};
