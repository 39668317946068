import { Grid } from '@mui/material';
import { HighlightsSortable } from './components/HighlightsSortable';
import * as React from 'react';

export const DashboardView: React.FC = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <HighlightsSortable group="main_headlines" title="Top stories" />
      </Grid>

      <Grid item xs={12} md={12}>
        <HighlightsSortable group="editors_choice" title="Editor's choice" />
      </Grid>
    </Grid>
  );
};
