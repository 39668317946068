import { Paper, TableFooter, TablePagination, TableRow } from '@mui/material';
import * as React from 'react';

interface Props {
  totalRows: number;
  rowsPerPage: number;
  page: number;
  standalone?: boolean;

  handleChangePage(
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ): void;
  handleChangeRowsPerPage({
    target: { value },
  }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void;
}

export const Pagination: React.FC<Props> = ({
  totalRows,
  rowsPerPage,
  page,
  standalone = false,

  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const paging = (
    <TablePagination
      labelRowsPerPage="Rows per page"
      labelDisplayedRows={({ from, to, count }) =>
        `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`
      }
      count={totalRows}
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );

  return standalone ? (
    <Paper>{paging}</Paper>
  ) : (
    <TableFooter>
      <TableRow>{paging}</TableRow>
    </TableFooter>
  );
};
