import { parseISO } from 'date-fns';
import { getApiClient } from 'lib/Helper/Api';
import { User, UsersResponse } from 'lib/Model/User';
import QueryString from 'qs';

function hydrateUser(data: any): User {
  return {
    ...data,
    last_login: data.last_login ? parseISO(data.last_login) : undefined,
  };
}

export function fetchUsers(
  search: string,
  pageNumber: number,
  itemsPerPage: number,
): Promise<UsersResponse> {
  return getApiClient()
    .get(`/api/users`, {
      params: {
        page: pageNumber,
        per_page: itemsPerPage,
        query: search,
      },
    })
    .then((d) => {
      const users = d.data?.data.map((u: any) => hydrateUser(u));
      return {
        ...d.data,
        data: users,
      };
    });
}

export function fetchUser(id: number): Promise<User> {
  return getApiClient()
    .get(`/api/users/${id}`)
    .then((d) => hydrateUser(d.data?.data));
}

export function createUser(user: User): Promise<User> {
  return getApiClient().post(`/api/users`, QueryString.stringify(user), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

export function updateUser(user: User): Promise<User> {
  return getApiClient()
    .put(`/api/users/${user.id}`, QueryString.stringify(user), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then((d) => hydrateUser(d.data?.data));
}

export function deleteUser(user: User): Promise<User> {
  return getApiClient()
    .delete(`/api/users/${user.id}`)
    .then((d) => hydrateUser(d.data?.data));
}
