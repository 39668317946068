import { getApiClient } from 'lib/Helper/Api';
import { Role, RolesResponse } from 'lib/Model/Role';
import QueryString from 'qs';

export function fetchRoles(
  search: string,
  pageNumber: number,
  itemsPerPage: number,
): Promise<RolesResponse> {
  return getApiClient()
    .get(`/api/roles`, {
      params: {
        page: pageNumber,
        per_page: itemsPerPage,
        query: search,
      },
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((d) => {
      return d.data;
    });
}

export function fetchRole(id: number): Promise<Role> {
  return getApiClient()
    .get(`/api/roles/${id}`, {
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((d) => {
      return d.data?.data;
    });
}

export function createRole(role: Role): Promise<Role> {
  return getApiClient()
    .post(`/api/roles`, QueryString.stringify(role), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then((d) => {
      return d.data?.data;
    });
}

export function updateRole(role: Role): Promise<Role> {
  return getApiClient()
    .put(`/api/roles/${role.id}`, QueryString.stringify(role), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then((d) => {
      return d.data?.data;
    });
}

export function deleteRole(role: Role): Promise<Role> {
  return getApiClient()
    .delete(`/api/roles/${role.id}`, {
      method: 'DELETE',
      responseType: 'json',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    })
    .then((d) => {
      return d.data?.data;
    });
}
