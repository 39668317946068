import {
  Badge,
  Button,
  ButtonProps,
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  SvgIcon,
  useTheme,
} from '@mui/material';
import { badgeClasses } from '@mui/base/Badge';
import { styled } from '@mui/system';
import MenuIcon from '@mui/icons-material/MoreVert';
import * as React from 'react';
import { DelayedLinearProgress } from 'components/DelayedLinearProgress';
import { grey } from '@mui/material/colors';
import { GetApp } from '@mui/icons-material';
export interface PopperMenuItem {
  label: string;
  subText?: string;
  badgeCount?: number;
  onClick?(): void;
  icon?: typeof SvgIcon;
  loading?: boolean;
  disabled?: boolean;
  iconColor?: string;
  isDivider?: boolean;
}

interface Props {
  disabled?: boolean;
  buttonText?: string;
  buttonColor?: ButtonProps['color'];
  menuItems: PopperMenuItem[];
  showBadge?: boolean;
  dropdownIcon?: React.ReactNode;
  buttonStyle?: React.CSSProperties;
  dropdownStyle?: React.CSSProperties;
  actionText?: string;
  onAction?: (...props: unknown[]) => void;
}

const ResponsivePopper = styled(Popper)(({ theme }) => ({
  zIndex: 9999,
}));

const StyledDropdownMenu = styled(MenuList)(() => ({
  padding: 0,
  maxHeight: 300,
  overflow: 'auto',
}));

const StyledMenuItem = styled(MenuItem)(() => ({
  whiteSpace: 'normal',
  margin: 0,
  display: 'flex',
  '&:hover': {
    backgroundColor: grey[300],
  },
}));

const StyledIcon = styled(GetApp)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0.5),
  },
}));

const StyledDropdownIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 'unset',
  marginRight: theme.spacing(1.5),
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  [`& .${badgeClasses.root}`]: {
    position: 'static',
  },
  [`& .${badgeClasses.badge}`]: {
    top: theme.spacing(2.5),
    right: theme.spacing(2),
  },
}));

export const PopperDropdownMenu: React.FC<Props> = ({
  buttonText,
  disabled,
  menuItems,
  dropdownIcon,
  showBadge,
  buttonStyle,
  dropdownStyle,
  buttonColor,
  actionText,
  onAction,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | undefined>();
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const shouldShowBadge =
    showBadge !== undefined
      ? showBadge
      : menuItems.filter((i) => i.badgeCount).length > 0;

  return (
    <React.Fragment>
      {buttonText ? (
        <Button
          disabled={disabled}
          onClick={(e) => setAnchorEl(e.currentTarget)}
          style={buttonStyle}
          color={buttonColor}
          startIcon={
            dropdownIcon ?? (
              <MenuIcon
                fontSize="small"
                style={{ marginRight: theme.spacing(0.5) }}
              />
            )
          }
        >
          {buttonText}
        </Button>
      ) : (
        <IconButton
          disabled={disabled}
          onClick={(e) => setAnchorEl(e.currentTarget)}
          style={buttonStyle}
        >
          {shouldShowBadge ? (
            <Badge badgeContent={1} variant="dot" color="secondary">
              {dropdownIcon ?? <MenuIcon fontSize="small" />}
            </Badge>
          ) : (
            dropdownIcon ?? <MenuIcon fontSize="small" />
          )}
        </IconButton>
      )}
      <ResponsivePopper
        placement="bottom-end"
        open={open}
        style={
          !open
            ? {
                pointerEvents: 'none',
              }
            : undefined
        }
        anchorEl={anchorEl}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={() => setAnchorEl(undefined)}>
            <Grow {...TransitionProps}>
              <Paper style={{ padding: 0 }}>
                <StyledDropdownMenu role="menu" style={dropdownStyle}>
                  {menuItems.map(
                    ({ loading, icon: Icon, iconColor, ...m }, i) => {
                      const key = `${m.label}-${i}`;

                      return m.isDivider ? (
                        <Divider key={key} />
                      ) : (
                        <StyledMenuItem
                          key={key}
                          onClick={() => {
                            if (!m.onClick) {
                              return;
                            }
                            m.onClick();
                            setAnchorEl(undefined);
                          }}
                          style={{ maxWidth: 300 }}
                          disabled={m.disabled}
                        >
                          {typeof loading !== 'undefined' && (
                            <DelayedLinearProgress loading={loading} />
                          )}
                          {Icon && (
                            <StyledDropdownIcon style={{ color: iconColor }}>
                              <Icon />
                            </StyledDropdownIcon>
                          )}

                          {m.badgeCount && m.badgeCount > 0 ? (
                            <StyledBadge
                              color="secondary"
                              badgeContent={m.badgeCount}
                            >
                              {m.label}
                            </StyledBadge>
                          ) : (
                            <ListItemText
                              primary={m.label}
                              secondary={m.subText}
                            />
                          )}
                        </StyledMenuItem>
                      );
                    },
                  )}
                  {onAction && actionText && (
                    <Button
                      onClick={onAction}
                      color="secondary"
                      component="span"
                    >
                      <StyledIcon />
                      {actionText}
                    </Button>
                  )}
                </StyledDropdownMenu>
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </ResponsivePopper>
    </React.Fragment>
  );
};
