import { Hidden, IconButton, Toolbar } from '@mui/material';
import { styled } from '@mui/system';
import { Menu } from '@mui/icons-material';
import { usePrevious } from 'hooks';
import * as React from 'react';
import {
  BreadcrumbsItem,
  BreadcrumbsProvider,
} from 'react-breadcrumbs-dynamic';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { DashboardView } from 'views/Dashboard/DashboardView';
import { Breadcrumbs, Sidebar, UpdateNotification } from './components';
import { AccessControlsView, ArticleEditor, ArticlesView } from 'views';
import { TagsTable } from 'views/AccessControls/components/TagsTable';
interface Props {
  componentError?: Error;

  waitingServiceWorker: ServiceWorker | null;
  updateServiceWorker(worker: ServiceWorker | null, fromBanner: boolean): void;
}

/*
    [theme.breakpoints.up('lg')]: 
  },*/

const MainContainer = styled('main')(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    marginLeft: theme.drawerWidth,
    paddingBottom: theme.spacing(5),
  },
}));

const HeaderToolbar = styled(Toolbar)(() => ({
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: 0,
}));

const MenuButton = styled(IconButton)(
  ({ theme }) => `
  margin-left: ${theme.spacing(-1)};
  margin-right: ${theme.spacing(0.5)};
`,
);

export const AppLayout: React.FC<Props> = ({
  waitingServiceWorker,
  updateServiceWorker,
}) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);

  const [redirectAfterLogin, setRedirectAfterLogin] = React.useState<
    string | undefined
  >();
  const prevRedirect = usePrevious(urlSearchParams.get('r'));

  React.useEffect(() => {
    if (!redirectAfterLogin || redirectAfterLogin === '/dashboard') {
      if (
        prevRedirect &&
        prevRedirect.trim().length > 0 &&
        prevRedirect !== '/'
      ) {
        setRedirectAfterLogin(prevRedirect);
      }
    } else if (!redirectAfterLogin) {
      setRedirectAfterLogin('/dashboard');
    }
  }, [redirectAfterLogin, prevRedirect]);

  return (
    <React.Fragment>
      <Sidebar
        open={drawerOpen}
        openDrawer={openDrawer}
        closeDrawer={closeDrawer}
      />

      <BreadcrumbsProvider>
        <MainContainer id="main">
          <HeaderToolbar>
            <Hidden lgUp>
              <MenuButton color="inherit" onClick={toggleDrawer}>
                <Menu />
              </MenuButton>
            </Hidden>
            <Breadcrumbs />
          </HeaderToolbar>
          {Boolean(waitingServiceWorker) && (
            <UpdateNotification
              updateServiceWorker={() =>
                updateServiceWorker(waitingServiceWorker, true)
              }
            />
          )}

          <Switch>
            {' '}
            <Route path="/dashboard">
              <BreadcrumbsItem to="/dashboard">Dashboard</BreadcrumbsItem>
              <DashboardView />
            </Route>
            <Route
              path="/articles/compose"
              render={(componentProps) => (
                <div>
                  <BreadcrumbsItem to="/articles">Articles</BreadcrumbsItem>
                  <BreadcrumbsItem to="/articles/compose">
                    New article
                  </BreadcrumbsItem>
                  <ArticleEditor {...componentProps} />
                </div>
              )}
            />
            <Route
              path="/articles/:id([0-9]+)"
              render={(componentProps) => (
                <div>
                  <BreadcrumbsItem to="/articles">Articles</BreadcrumbsItem>
                  <BreadcrumbsItem
                    to={`/articles/${componentProps.match.params.id}`}
                  >
                    Edit article
                  </BreadcrumbsItem>
                  <ArticleEditor {...componentProps} />
                </div>
              )}
            />
            <Route path="/articles">
              <BreadcrumbsItem to="/articles">Articles</BreadcrumbsItem>
              <ArticlesView />
            </Route>
            <Route path="/tags">
              <BreadcrumbsItem to="/tags">Tags</BreadcrumbsItem>
              <TagsTable />
            </Route>
            <Route path="/access-controls">
              <BreadcrumbsItem to="/access-controls">
                Access controls
              </BreadcrumbsItem>
              <AccessControlsView />
            </Route>
            <Route>
              <Redirect to={redirectAfterLogin ?? '/dashboard'} />
            </Route>
          </Switch>
          <div style={{ height: 20 }} />
        </MainContainer>
      </BreadcrumbsProvider>
    </React.Fragment>
  );

  function toggleDrawer() {
    setDrawerOpen((open) => !open);
  }

  function openDrawer() {
    if (!drawerOpen) {
      toggleDrawer();
    }
  }

  function closeDrawer() {
    if (drawerOpen) {
      toggleDrawer();
    }
  }
};
