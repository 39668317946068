import { Typography, Button, useTheme } from '@mui/material';
import brandLogo from '../../layouts/App/assets/timesofmalta.png';
import * as React from 'react';
import { StyledLogo, StyledWrapper } from './StyledComponents';

export const FetchFailed: React.FC = () => {
  const theme = useTheme();

  return (
    <StyledWrapper>
      <a href="https://exp42.com" target="_blank" rel="noopener noreferrer">
        <StyledLogo src={brandLogo} alt="TimesOfMalta" />
      </a>
      <Typography color="inherit" align="center" component="div">
        <Typography variant="h5" gutterBottom>
          Your browser is not able to reach TOM CMS at this moment.
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          This may result from a networking problem. Please try reloading by
          pressing the button below.
        </Typography>
        <div style={{ margin: theme.spacing(2, 0) }}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => document.location.reload()}
          >
            Click to reload
          </Button>{' '}
        </div>
      </Typography>
    </StyledWrapper>
  );
};
