import { Grid } from '@mui/material';
import { AppTabs } from 'components';
import * as React from 'react';
import { UsersTable } from './components/UsersTable';
import { RolesTable } from './components/RolesTable';
import { AuditLogsTable } from './components/AuditLogsTable';
import { AuthorsTable } from './components/AuthorsTable';

export const AccessControlsView: React.FC = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <AppTabs
          tabs={[
            { label: 'Users', value: 'users' },
            { label: 'Roles', value: 'roles' },
            { label: 'Authors', value: 'authors' },
            { label: 'Audit logs', value: 'audit-logs' },
          ]}
        >
          {(activeTab) => {
            switch (activeTab) {
              case 'users':
                return <UsersTable />;
              case 'roles':
                return <RolesTable />;
              case 'authors':
                return <AuthorsTable />;
              case 'audit-logs':
                return <AuditLogsTable />;
            }
          }}
        </AppTabs>
      </Grid>
    </Grid>
  );
};
