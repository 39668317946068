import { bbcodeTag } from '../helpers/bbcode_tag_creator';
import tinymce from 'tinymce';

export const CustomPluginInstagram = () => {
  tinymce.PluginManager.add('tomInstagram', (editor, url) => {
    const openInstagram = () => {
      return editor.windowManager.open({
        title: 'Embed Instagram',
        body: {
          type: 'panel',
          items: [
            {
              type: 'input',
              name: 'url',
              placeholder: 'Instagram URL',
            },
            {
              type: 'listbox',
              label: 'Hide caption:',
              name: 'hidecaption',
              items: [
                {
                  text: 'Show',
                  value: 'false',
                },
                {
                  text: 'Hide',
                  value: 'true',
                },
              ],
            },
          ],
        },
        buttons: [
          {
            type: 'cancel',
            text: 'Close',
          },
          {
            type: 'submit',
            text: 'Save',
            primary: true,
          },
        ],
        onSubmit: (api) => {
          const { url, hidecaption } = api.getData();

          try {
            const trimmedUrl = new URL(url.trim());
            if (!trimmedUrl || !trimmedUrl.href) {
              return;
            }

            const splitUrl = trimmedUrl.href.split('?').shift();
            if (!splitUrl) {
              return;
            }

            editor.insertContent(
              bbcodeTag('instagram', '', {
                url: splitUrl,
                hidecaption: hidecaption.trim(),
              }),
            );
          } catch (ex) {
            tinymce.activeEditor?.windowManager.alert('Invalid URL');
          }

          api.close();
        },
      });
    };
    editor.ui.registry.addButton('tomInstagram', {
      icon: 'instagram',
      tooltip: 'Embed Instagram post',
      onAction: () => openInstagram(),
    });
  });
};
