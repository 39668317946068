import * as React from 'react';

export type SnackbarInputType = Error | string;

export interface Snackbar {
  info: (input: SnackbarInputType) => void;
  success: (input: SnackbarInputType) => void;
  warn: (input: SnackbarInputType) => void;
  error: (input: SnackbarInputType) => void;
  close: () => void;
  isOpen: boolean;
  message: string;
  severity: 'success' | 'info' | 'error' | 'warning';
}

export const SnackbarContext = React.createContext<Snackbar>({
  isOpen: false,
  message: '',
  severity: 'success',

  close() {},
  info() {},
  success() {},
  error() {},
  warn() {},
});
