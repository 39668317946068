import * as React from 'react';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Drawer,
  IconButton,
  InputAdornment,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  PageableTable,
  PageableTableRow,
  PopperDropdownMenu,
} from 'components';
import { PageableTableHeader } from 'components/PageableTable/components';
import { Search } from '@mui/icons-material';

interface LogEntry {
  id: number;
  text: string;
}

export const AuditLogsTable: React.FC = () => {
  const [textSearch, setTextSearch] = React.useState('');

  const [selectedLogEntry, setSelectedLogEntry] = React.useState<
    LogEntry | undefined
  >();

  const columns = React.useMemo(() => {
    return [
      {
        key: 'date',
        label: '',
        sortable: false,
        props: {
          style: { width: 60 },
        },
      },
      {
        key: 'role',
        label: 'Log entry',
        sortable: true,
      },
      {
        key: 'actions',
        label: '',
        sortable: true,
      },
    ] as PageableTableHeader[];
  }, []);

  const logsData = React.useMemo(() => {
    return [
      {
        text: 'Online Editor',
        id: 1,
      },
    ] as LogEntry[];
  }, []);

  const rows: PageableTableRow[] = logsData
    .filter((r) => {
      return (
        !textSearch || r.text.toLowerCase().includes(textSearch.toLowerCase())
      );
    })
    .map((l) => {
      return {
        key: `logentry_${l.id}`,
        cells: [
          {
            key: 'date',
            display: (
              <Typography>Date</Typography>
            ),
          },
          {
            key: 'role',
            display: <Typography>{l.text}</Typography>,
          },
          {
            key: 'actions',
            props: {
              style: {
                textAlign: 'right',
              },
            },
            display: (
              <PopperDropdownMenu
                menuItems={[
                  {
                    label: 'More detail',
                    onClick: () => setSelectedLogEntry(l),
                  },
                ]}
              />
            ),
          },
        ],
      } as PageableTableRow;
    });

  return (
    <Card>
      <CardHeader title="Audit log" />
      <Toolbar>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Type here to find audit log entry.."
          value={textSearch}
          onChange={(e) => setTextSearch(e.target.value ?? '')}
          autoFocus
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>
      <PageableTable columns={columns} rows={rows} onChangePage={() => {}} />

      <Drawer
        open={Boolean(selectedLogEntry)}
        anchor="right"
        onClose={() => setSelectedLogEntry(undefined)}
      >
        <Box style={{ minWidth: '30vw', padding: 16 }}>
          <Box
            style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}
          >
            {selectedLogEntry?.text}
          </Box>

          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 8,
              paddingTop: 8,
            }}
          >
            <Button
              variant="text"
              color="inherit"
              onClick={() => setSelectedLogEntry(undefined)}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Drawer>
    </Card>
  );
};
