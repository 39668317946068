import { Button, Divider } from '@mui/material';
import { styled } from '@mui/system';

export const StyledWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: 'center',
  maxWidth: 600,
  margin: '0px auto',
}));

export const StyledLogo = styled('img')(({ theme }) => ({
  height: '30px',
  margin: theme.spacing(3, 0),
}));

export const SuccessButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  '&:hover': {
    backgroundColor: theme.palette.success.dark,
  },
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(3, 0),
}));

export const StyledPrimaryLink = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
}));
