import { Button } from '@mui/material';
import { styled } from '@mui/system';
import { RouterNavLink } from 'components';
import * as React from 'react';
import { BreadcrumbsItemProps } from 'react-breadcrumbs-dynamic';

const StyledNavLink = styled(RouterNavLink)(({ theme }) => ({
  '&.active': {
    color: theme.palette.text.primary,
  },
}));

export const BreadcrumbItem: React.FC<BreadcrumbsItemProps> = (props) => {
  return (
    <Button
      component={StyledNavLink}
      exact
      variant="text"
      size="small"
      color="secondary"
      {...props}
    />
  );
};
