import { ResponseMeta } from './ResponseMeta';

export interface Author {
  id: number;
  date?: Date; // TODO: check
  user_id?: number; // TODO: check

  author_type_id?: number;
  display_name?: string;
  picture?: string;

  description?: string;
  website?: string;
  email?: string;
  facebook?: string;
  twitter?: string;
  instagram?: string;

  visible: number; // Due to tinyint fields in db
  active: number; // Due to tinyint fields in db

  created: Date;
  modified: Date;
}

export interface AuthorsResponse {
  success: boolean;
  message: string;
  data?: Author[];
  meta?: ResponseMeta;
}

export function getNewAuthor(): Author {
  return {
    id: -1,
    author_type_id: 1, // TODO: check about this
    user_id: -1, // TODO: check about this
    visible: 0,
    active: 0,
    created: new Date(),
    modified: new Date(),
  };
}
