import axios from 'axios';
import { Config } from 'config';
import { clearAuthData } from './Auth';

const servicesApi = axios.create({
  baseURL: Config.API_BASE_URL,
  withCredentials: true,
  responseType: 'json',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    common: {
      Accept: 'application/json',
    },
    'Content-Type': 'application/json',
  },
});

servicesApi.interceptors.response.use(
  (response) => response,
  (error) => {
    const statusCode = error.response ? error.response.status : null;
    if (statusCode === 401) {
      clearAuthData();
      window.location.reload();
    }
    // whatever you want to do with the error
    throw error;
  },
);

export function getApiClient() {
  return servicesApi;
}
