import tinymce from 'tinymce';

export const CustomIcons = () => {
  tinymce.IconManager.add('TOM_pack_1', {
    icons: {
      email:
        '<svg width="20" height="20" viewBox="0 0 24 24"><path d="M19 10V5a2 2 0 00-2-2H2a2 2 0 00-2 2v10c0 1.1.9 2 2 2h11.2a5.5 5.5 0 008 3.3c.3-.2.4-.5.2-.7a.5.5 0 00-.6-.2 4.5 4.5 0 112.2-3.9v.5a1 1 0 01-2 0v-2a.5.5 0 00-.8-.4c-.5-.4-1-.6-1.7-.6a2.5 2.5 0 000 5c.7 0 1.4-.3 1.9-.9A2 2 0 0024 16v-.5c0-2.9-2.2-5.2-5-5.5zM2 4h15L10 10H9L2 4H2zm16 6a5.5 5.5 0 00-5 6H2a1 1 0 01-1-1V5v-.4l7.5 6.1a1.8 1.8 0 002 0L18 4.6l.1.4zm.5 7a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm0 0"/></svg>',
      facebook:
        '<svg width="20" height="20" viewBox="0 0 24 24"><path d="M24 12a12 12 0 10-12 12h.2v-9.3H9.6v-3h2.6V9.4c0-2.5 1.6-4 3.9-4l2.3.2v2.7h-1.6c-1.2 0-1.5.6-1.5 1.4v2h3l-.4 3h-2.6v8.8c5-1.4 8.7-6 8.7-11.5zm0 0"/></svg>',
      iframe:
        '<svg width="20" height="20" viewBox="0 0 24 24"><path d="M9.5 16.8h-.3l-2.6-2.3v-.3-.3l2.6-2.1c.2-.2.4-.2.6 0v.6l-2.3 1.8 2.2 2c.2 0 .2.3 0 .5l-.2.1zm0 0M14.5 16.8l-.3-.1a.4.4 0 010-.6l2.3-1.9-2.2-1.8a.4.4 0 010-.6c0-.2.3-.2.5 0l2.6 2.1a.4.4 0 010 .6l-2.6 2.2-.3.1zm0 0M11 18.5a.4.4 0 01-.3-.5l1.6-7.6c0-.2.3-.4.5-.3.2 0 .3.2.3.5L11.5 18c0 .2-.2.4-.4.4zm0 0"/><path d="M21.3 22.6H2.7A2.7 2.7 0 010 19.9V4.1c0-1.5 1.2-2.7 2.7-2.7h18.6c1.5 0 2.7 1.2 2.7 2.7v15.8c0 1.5-1.2 2.7-2.7 2.7zM2.7 2.2c-1 0-2 .8-2 1.9v15.8c0 1 1 1.9 2 1.9h18.6c1 0 2-.8 2-1.9V4.1c0-1-1-1.9-2-1.9zm0 0"/><path d="M23.6 7.8H.4a.4.4 0 010-.8h23.2c.2 0 .4.1.4.4 0 .2-.2.4-.4.4zm0 0M6.8 5.9a1.3 1.3 0 110-2.5 1.3 1.3 0 010 2.5zm0-1.8a.5.5 0 000 1 .5.5 0 000-1zm0 0M3 5.9a1.3 1.3 0 110-2.6A1.3 1.3 0 013 6zM3 4a.5.5 0 000 1 .5.5 0 000-1zm0 0M10.6 5.9a1.3 1.3 0 110-2.5 1.3 1.3 0 010 2.5zm0-1.7a.5.5 0 100 .9.5.5 0 000-1zm0 0"/></svg>',
      instagram:
        '<svg width="20" height="20" viewBox="0 0 1600 1600"><path d="M447.5 101.1a385 385 0 00-150.7 45 471.6 471.6 0 00-55.6 37.1c-26.2 21-57 54.5-76.1 82.8A379.2 379.2 0 00106 410.4c-5.8 32-5.4 9.7-5.8 329.6-.2 205.5 0 298 .8 308.5a377.1 377.1 0 00209.5 312 364 364 0 00141.7 38.5c9.8.8 106.5 1 307.8.8 270-.4 294.5-.5 306-2.1 50-6.8 87.8-18.5 129.6-40.1a358.8 358.8 0 0094.4-69.5 375.7 375.7 0 00108.2-227.6c1.9-17.7 1.9-603.3 0-621A378.5 378.5 0 001050.1 101c-16.5-1.2-586.2-1.1-602.6.1zM1063 202.8a276.7 276.7 0 01234.2 234.3l2.3 14.4v597l-2.3 14.4a276.4 276.4 0 01-212.2 230c-31.9 7.2-4 6.6-335 6.6-281.4 0-298.1-.1-309.5-1.8a276.4 276.4 0 01-160.2-79.9 272.1 272.1 0 01-79.3-172.9c-.8-9.3-1-101.5-.8-303.9l.3-290.5 2.3-13.9c2.8-16.8 8.8-40.6 13.5-53.6a278 278 0 01147.9-159.2 291.2 291.2 0 0192.8-23.2c1.9-.1 136-.2 298-.2l294.5.1 13.5 2.3z"/><path d="M1138.3 301.4A51 51 0 001100 350c0 27 23 50 50 50 13 0 25.1-5.1 34.9-14.9a48.6 48.6 0 00.2-70.2 49.1 49.1 0 00-46.8-13.5zM728 400.6l-14.5 1.4a350 350 0 00-140.3 46.1 352.4 352.4 0 00-171.4 265.4 523 523 0 000 73 350.3 350.3 0 00112.7 221.8 351.4 351.4 0 00199 89.9 523 523 0 0073 0 350.4 350.4 0 00244.3-140.1 347 347 0 0068.9-217.6 339.7 339.7 0 00-38.6-150 331.7 331.7 0 00-64.6-88.1 335.1 335.1 0 00-95.5-67.8 340.3 340.3 0 00-110-32.2c-11-1.4-55.6-2.7-63-1.8zM772.5 501a252.5 252.5 0 01131 51.3 347 347 0 0138.1 36.8 255 255 0 0156 125.4c2.5 16.6 2.6 53.2.1 70a250 250 0 01-489.7 28 206.2 206.2 0 01-7.4-62.5c0-27.1 1.3-38.6 7.4-62.5 9-35.4 24.9-66.9 48.3-96 8.4-10.4 30-31.4 40.2-39.2a252.6 252.6 0 01176-51.3z"/></svg>',
      'paper-clip':
        '<svg width="20" height="20" viewBox="0 0 24 24"><path d="M23 8.7a1 1 0 00-1.4 0l-12 12.1a4 4 0 01-5.7-5.6L16.3 2.7c1-1 2.5-1 3.5 0s1 2.6 0 3.6L9.6 16.5A1 1 0 018 15.1l5-5a1 1 0 10-1.4-1.4l-5 5a3 3 0 104.3 4.2L21.2 7.7A4.5 4.5 0 0015 1.3L2.5 13.8a6 6 0 008.5 8.4l12-12c.4-.4.4-1 0-1.5zm0 0"/></svg>',
      related:
        '<svg width="20" height="20" viewBox="0 0 24 24"><path d="M12 15.7a3.7 3.7 0 11.1-7.4 3.7 3.7 0 010 7.4zM12 9a3 3 0 100 6.2 3 3 0 000-6.2zm0 0"/><path d="M15.5 12a.3.3 0 010-.6l4-.6c.3 0 .4 0 .4.2s0 .4-.2.4l-4.2.6zm0 0M19.4 18.4h-.2l-5-3.7a.3.3 0 010-.5h.5l4.9 3.6.1.4-.3.2zm0 0M8.8 20.7c-.1 0-.2 0-.3-.2v-.3l2.2-5.1.2-.2.3.1v.3l-2.1 5.2-.3.2zm0 0M4.6 14a.3.3 0 01-.3-.2c0-.2 0-.3.2-.4l4.2-1c.2 0 .3 0 .4.2 0 .2 0 .4-.2.4l-4.2 1h-.1zm0 0M9.5 10.1h-.2L6.3 8v-.3a.3.3 0 01.5-.3l2.9 2.2a.3.3 0 01-.2.5zm0 0M12.6 9c-.1 0-.2 0-.3-.2v-.2L13.4 4a.3.3 0 01.7.2l-1.2 4.4c0 .2-.2.3-.3.3zm0 0"/><path d="M4.8 9a2.5 2.5 0 110-5 2.5 2.5 0 010 5zm0-4.4a1.8 1.8 0 100 3.7 1.8 1.8 0 000-3.7zm0 0M14.2 4.6a2 2 0 110-4 2 2 0 010 4zm0-3.3a1.3 1.3 0 100 2.6 1.3 1.3 0 000-2.6zm0 0M20.9 12.5a1.6 1.6 0 110-3.2 1.6 1.6 0 010 3.2zm0-2.6a1 1 0 100 2 1 1 0 000-2zm0 0M20.8 21.2a2.1 2.1 0 01-1-4 2.1 2.1 0 111 4zm0-3.5h-.1a1.5 1.5 0 10.1 0zm0 0M8.5 23.4a1.7 1.7 0 110-3.4 1.7 1.7 0 010 3.4zm0-2.8a1 1 0 100 2 1 1 0 000-2zm0 0M3 16.3a2 2 0 110-4 2 2 0 010 4zM3 13a1.3 1.3 0 100 2.6A1.3 1.3 0 003 13zm0 0"/></svg>',
      slider:
        '<svg width="20" height="20" viewBox="0 0 24 24"><path d="M8 18a1 1 0 01-2 0 1 1 0 012 0zm0 0M7.7 12.3a1 1 0 11-1.4 1.4l-3-3a1 1 0 010-1.4l3-3a1 1 0 111.4 1.4L5.4 10zm0 0M13 18a1 1 0 01-2 0 1 1 0 012 0zm0 0M18 18a1 1 0 01-2 0 1 1 0 012 0zm0 0M20.7 10.7l-3 3a1 1 0 01-1.4 0 1 1 0 010-1.4l2.3-2.3-2.3-2.3a1 1 0 111.4-1.4l3 3c.4.4.4 1 0 1.4zm0 0"/><path d="M21 24H3a3 3 0 01-3-3V3a3 3 0 013-3h18a3 3 0 013 3v18a3 3 0 01-3 3zM3 2a1 1 0 00-1 1v18c0 .6.4 1 1 1h18c.6 0 1-.4 1-1V3c0-.6-.4-1-1-1zm0 0"/></svg>',
      twitter:
        '<svg width="20" height="20" viewBox="0 0 24 24"><path d="M21.3 11.1c.3.2.7.3 1.2.3a7.3 7.3 0 001.5 0 4 4 0 01-1.1.7c-.5.2-1 .3-1.8.3-.3 1.2-.9 2.3-1.8 3.3s-2 1.8-3.2 2.4a13.7 13.7 0 01-6.5 1.6 13.3 13.3 0 01-6-1.5c-1.4-.7-2.6-1.7-3.6-3a6 6 0 002 1.2 7 7 0 004.8 0A6 6 0 008.9 15h-1c-.3-.2-.5-.3-.6-.6v-.6c.1-.3.4-.5.9-.7a3.8 3.8 0 01-1.1 0 2.6 2.6 0 01-1.5-.8l-.4-.5.7-.4 1-.2a4 4 0 01-2-.9c-.4-.4-.7-.8-.7-1.3a22.5 22.5 0 001.4-.2c-.8-.4-1.3-.9-1.6-1.4-.3-.4-.4-.9-.4-1.3V6a61.7 61.7 0 017 3l1 .9A18.6 18.6 0 0113 6.8c.3-.5.6-1 1-1.3.3-.4.7-.8 1.2-1l-.3.7c.2-.3.5-.5.8-.6a4 4 0 011-.3c0 .2-.2.4-.5.6l-.7.3.4-.1a7.4 7.4 0 011.5-.4l.4.1-.1.3a2 2 0 01-.5.2 6 6 0 01-.5.2h-.4l-.6.3h.4c.4 0 .9 0 1.3.2.6.1 1 .4 1.5.7a5.9 5.9 0 012 2.8v.5l.8.1h.8a5.7 5.7 0 001.3-.4 2 2 0 01-1 1c-.4.2-1 .4-1.6.4zm0 0"/></svg>',
      audio:
        '<svg width="20" height="20" viewBox="0 0 24 24"><path d="M14 .9a.8.8 0 00-.9 0L5.3 7.3H1.6C.7 7.2 0 7.9 0 8.8v6.4c0 .9.7 1.6 1.6 1.6h3.7l7.8 6.2a.8.8 0 00.8.1c.3-.1.5-.4.5-.7V1.6c0-.3-.2-.6-.5-.7zM4.7 15.2H1.6V8.8h3.2zm8 5.5l-6.4-5V8.3l6.4-5.1zm0 0M18.5 6.3a.8.8 0 10-1.2 1.2 6.3 6.3 0 011.9 4.5c0 1.7-.7 3.3-1.9 4.5a.8.8 0 101.2 1.2 7.9 7.9 0 002.3-5.7c0-2.2-.8-4.2-2.3-5.7zm0 0"/><path d="M20.7 4a.8.8 0 10-1.1 1.1 9.5 9.5 0 010 13.7.8.8 0 00.5 1.3c.2 0 .5 0 .6-.2a11 11 0 000-15.8zm0 0"/></svg>',
      youtube:
        '<svg width="20" height="20" viewBox="0 0 24 24"><path d="M21.7 12.1c-.2-1-1.1-1.9-2.2-2-2.5-.3-5-.3-7.5-.3-2.6 0-5 0-7.6.3-1 .1-2 1-2.2 2C2 13.6 2 15.3 2 17c0 1.6 0 3.2.3 4.8.3 1 1.1 1.8 2.2 2 2.5.2 5 .2 7.6.2 2.5 0 5 0 7.5-.3 1 0 2-.9 2.2-2 .4-1.5.4-3.1.4-4.7s0-3.3-.4-4.8zm-14 1.2H6.1V21H4.8v-7.8H3.4V12h4.2zm3.6 7.8h-1.2v-.8c-.5.6-1 .8-1.4.8-.4 0-.7-.1-.8-.5l-.1-1v-5.3H9v5.5l.3.2c.3 0 .5-.1.8-.5v-5.2h1.2zm4.6-2v1.3c-.2.5-.6.7-1 .7-.5 0-1-.2-1.3-.7v.7h-1.2v-9h1.2V15c.4-.5.8-.8 1.2-.8.5 0 .9.3 1 .8l.1 1.4zm4.6-1.2h-2.4v1.2c0 .6.2 1 .6 1 .3 0 .5-.2.6-.5v-.9h1.2v1a1.7 1.7 0 01-1.7 1.4c-.7 0-1.2-.2-1.5-.6-.3-.4-.4-1-.4-1.6v-2.4c0-.7.1-1.2.4-1.6.3-.4.8-.7 1.4-.7.6 0 1.1.3 1.5.7.2.4.3.9.3 1.6zm0 0"/><path d="M18.7 15.3c-.4 0-.6.4-.6 1v.6h1.2v-.6c0-.6-.2-1-.6-1zm0 0M14.2 15.3l-.6.3v4.1l.6.3c.3 0 .5-.3.5-.9v-2.8c0-.6-.2-1-.5-1zm0 0M14.9 9.2c.4 0 .9-.3 1.4-.8V9h1.2V2.3h-1.2v5.2c-.3.4-.6.6-.8.6-.2 0-.3 0-.3-.3V2.3H14v6.4c.2.4.5.5.9.5zm0 0M6.3 5.4v3.7h1.4V5.4L9.3 0H7.9L7 3.6 6 0H4.6l.9 2.5.8 3zm0 0M11.1 9.2c.7 0 1.1-.2 1.5-.7.2-.3.4-.9.4-1.6V4.5c0-.7-.2-1.2-.4-1.6-.4-.4-.8-.7-1.5-.7-.6 0-1 .3-1.4.7-.3.4-.4 1-.4 1.6V7c0 .7.1 1.3.4 1.6.3.5.8.7 1.4.7zm-.6-4.9c0-.6.2-1 .6-1 .4 0 .6.4.6 1v2.9c0 .6-.2 1-.6 1-.4 0-.6-.4-.6-1zm0 0"/></svg>',
      bold: '<svg width="20" height="20"><path d="M7.8 19c-.3 0-.5 0-.6-.2l-.2-.5V5.7c0-.2 0-.4.2-.5l.6-.2h5c1.5 0 2.7.3 3.5 1 .7.6 1.1 1.4 1.1 2.5a3 3 0 01-.6 1.9c-.4.6-1 1-1.6 1.2.4.1.9.3 1.3.6s.8.7 1 1.2c.4.4.5 1 .5 1.6 0 1.3-.4 2.3-1.3 3-.8.7-2.1 1-3.8 1H7.8zm5-8.3c.6 0 1.2-.1 1.6-.5.4-.3.6-.7.6-1.3 0-1.1-.8-1.7-2.3-1.7H9.3v3.5h3.4zm.5 6c.7 0 1.3-.1 1.7-.4.4-.4.6-.9.6-1.5s-.2-1-.7-1.4c-.4-.3-1-.4-2-.4H9.4v3.8h4z" fill-rule="evenodd"/></svg>',
      tiktok:
        '<svg width="20" height="20"><path d="M18.1 6.6v3c0 .4-.3.7-.6.7a8 8 0 01-3.8-.9v2.8a6 6 0 11-7-5.9.6.6 0 01.8.7v3.2c0 .3-.1.5-.4.6-.7.3-1 1.1-.8 1.8a1.6 1.6 0 003-.4v-10c0-.4.4-.6.7-.6h3.1c.4 0 .7.2.7.6 0 2 1.6 3.7 3.7 3.7.3 0 .6.3.6.7zm0 0"/></svg>',
      video:
        '<svg width="20px" height="20px" viewBox="0 0 24 24"><path d="M4,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 Z M5,5 L5,19 L19,19 L19,5 L5,5 Z M9.7906191,7.56472793 L15.4303866,11.5931333 C15.6550929,11.7536378 15.7071387,12.0659128 15.5466342,12.2906191 C15.5145644,12.3355169 15.4752843,12.3747969 15.4303866,12.4068667 L9.7906191,16.4352721 C9.5659128,16.5957766 9.25363776,16.5437307 9.09313326,16.3190244 C9.03256157,16.2342241 9,16.1326168 9,16.0284053 L9,7.97159466 C9,7.69545229 9.22385763,7.47159466 9.5,7.47159466 C9.60421149,7.47159466 9.70581872,7.50415623 9.7906191,7.56472793 Z" fill="#000000" fill-rule="nonzero"></path></svg>',
    },
  });
};
