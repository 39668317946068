const tagAttributesToString = (obj: any) => {
  const result = [];

  for (const p in obj) {
    if (obj.hasOwnProperty(p)) {
      result.push(`${p}="${obj[p]}"`);
    }
  }
  return result.join(' ');
};

const openTag = (name: string | undefined, attr?: object) => {
  return 'object' === typeof attr
    ? `[${name} ${tagAttributesToString(attr)}]`
    : `[${name}]`;
};

const closeTag = (name: string | undefined) => {
  if (!name) {
    return '';
  }
  return `[/${name}]`;
};

export const bbcodeTag = (
  name: string | undefined,
  value: string,
  attr?: object,
) => {
  return [
    "<span class='bbcode'>",
    openTag(name, attr),
    value,
    closeTag(name),
    '</span>',
  ].join('');
};
