import { AppBar, Button, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import * as React from 'react';

interface Props {
  updateServiceWorker(): void;
}

export const UpdateNotification: React.FC<Props> = ({
  updateServiceWorker,
}) => {
  return (
    <AppBar position="relative" component="div">
      <Typography variant="h6" component="span">
        A new update is available! Please refresh to get the latest version of
        TOM CMS.
      </Typography>

      <Button color="inherit" onClick={updateServiceWorker} variant="outlined">
        <RefreshIcon />
        Click to Refresh
      </Button>
    </AppBar>
  );
};
