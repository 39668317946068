import { Typography } from '@mui/material';
import { Config } from 'config';
import * as React from 'react';
import ErrorSvg from './error.svg';
import { ErrorMessage } from './ErrorMessage';
import { FetchFailed } from './FetchFailed';
import { RefreshRequired } from './RefreshRequired';
import { UnsupportedBrowser } from './UnsupportedBrowser';

export interface Props {
  error: Error;
  helpMsg?: string;
}

export const ErrorContent: React.FC<Props> = ({ error, helpMsg }) => {
  if (error.message.indexOf('Failed to fetch') >= 0) {
    return <FetchFailed />;
  } else if (error.message.indexOf('Received status code 400') >= 0) {
    return <RefreshRequired />;
  } else if (error.message.includes('serviceWorker')) {
    return <UnsupportedBrowser />;
  }

  return (
    <div style={{ padding: 30, textAlign: 'center' }}>
      <img src={ErrorSvg} alt="Empty" style={{ height: 60 }} />

      <Typography color="inherit" align="center" component="div">
        <Typography color="inherit" variant="h5" gutterBottom>
          Shoot! We've encountered an error.
        </Typography>

        {helpMsg && <Typography color="inherit">{helpMsg}</Typography>}

        <Typography color="inherit" component="div">
          <ErrorMessage error={error} showStack={Config.DEBUG} />
        </Typography>
      </Typography>
    </div>
  );
};
