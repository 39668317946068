import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemText,
  SwipeableDrawer,
  Tooltip,
  useTheme,
} from '@mui/material';
import { stringAvatar } from 'lib/Helper/Avatars';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Dashboard,
  Security,
  Announcement,
  LocalOffer,
  AddBusiness,
  PhotoLibrary,
  Article,
  Home,
} from '@mui/icons-material';
import { PopperDropdownMenu, RouterNavLink } from 'components';
import { getUserData, logout } from 'lib/Helper/Auth';
import * as React from 'react';
import { styled } from '@mui/system';

interface Props {
  openDrawer: () => void;
  closeDrawer: () => void;
  open: boolean;
}

const StyledList = styled(List)(({ theme }) => ({
  overflow: 'auto',
  flex: 1,
  width: theme.drawerWidth,
  height: '100%',
  zIndex: 4,
}));

const StyledNavLink = styled(RouterNavLink)(({ theme }) => ({
  transition: 'all 150ms linear',
  margin: theme.spacing(0.5),
  padding: theme.spacing(2, 1),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: 'auto',
  borderRadius: theme.spacing(0.5),
  color: theme.palette.primary.main,
  '&.active': {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    '&:hover, &:focus': {
      color: theme.palette.primary.contrastText,
      backgroundColor: '#00',
    },
  },
}));

export const Sidebar: React.FC<Props> = ({ open, openDrawer, closeDrawer }) => {
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));

  React.useEffect(() => {
    // When screen is large, we set the open state to false, so when the user minimizes screen size,
    // the drawer will start in a closed state.
    if (isLg && open) {
      closeDrawer();
    }
  });

  interface MenuRoute {
    path: string;
    label: string;
    secondary?: string;
    icon?: React.ReactNode;
    onClick?: () => void;
    divider?: boolean;
  }

  const user = getUserData();
  if (!user) {
    return null;
  }

  // const permissions = user.permissions ?? [];
  // TODO: permission checks

  const routes: MenuRoute[] = [
    {
      path: '/dashboard',
      label: 'Dashboard',
      icon: <Dashboard />,
    },
  ];

  if (true) {
    routes.push({
      path: '/articles',
      label: 'Articles',
      icon: <Article />,
    });
    routes.push({
      path: '/media',
      label: 'Media Library',
      icon: <PhotoLibrary color="disabled" />,
    });
    routes.push({
      path: '/breaking',
      label: 'Breaking news',
      icon: <Announcement color="disabled" />,
      onClick: () => {
        // Not implemented
      },
    });
    routes.push({
      path: '',
      label: '',
      divider: true,
    });
  }

  routes.push(
    ...[
      {
        path: '/home-page',
        label: 'Homepage',
        icon: <Home color="disabled" />,
      },
      {
        path: '/tags',
        label: 'Tags',
        icon: <LocalOffer />,
      },
      {
        path: '/classifieds',
        label: 'Classifieds',
        icon: <AddBusiness color="disabled" />,
      },
      {
        path: '',
        label: '',
        divider: true,
      },
      {
        path: '/access-controls',
        label: 'Access controls',
        icon: <Security />,
      },
    ],
  );

  return (
    <SwipeableDrawer
      anchor="left"
      ModalProps={{ keepMounted: true }}
      variant={isLg ? 'permanent' : 'temporary'}
      open={isLg || open}
      onClose={closeDrawer}
      onOpen={openDrawer}
      style={{
        width: theme.drawerWidth + 1,
      }}
    >
      <StyledList dense>
        {routes.map(
          ({ path, label, secondary, icon, divider, onClick }, index) =>
            divider ? (
              <React.Fragment key={`menuitem_${index}`}>
                <Divider
                  key={`menuitem_${index}`}
                  style={{
                    margin: theme.spacing(1, 0),
                  }}
                />
                {label && (
                  <React.Fragment>
                    <ListItem>
                      <ListItemText
                        primary={label}
                        primaryTypographyProps={{ style: { fontWeight: 500 } }}
                      />
                    </ListItem>
                    <Divider key={`menuitem_${index}`} />
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <Tooltip
                title={label}
                placement="right"
                key={`menuitem_${index}`}
              >
                <StyledNavLink
                  onClick={() => (onClick ? onClick() : handleClick())}
                  key={path}
                  to={path}
                >
                  {icon}
                </StyledNavLink>
              </Tooltip>
            ),
        )}
      </StyledList>

      {user && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            zIndex: 2,
            alignItems: 'center',
          }}
        >
          <PopperDropdownMenu
            dropdownIcon={
              <Avatar
                {...stringAvatar(`${user.first_name} ${user.last_name}`)}
              />
            }
            menuItems={[
              {
                label: 'Logout',
                onClick: async () => {
                  await logout();
                  window.location.reload();
                },
              },
            ]}
          />
        </div>
      )}
    </SwipeableDrawer>
  );

  function handleClick() {
    closeDrawer();
  }
};
