import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  TextField,
} from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import styled from '@emotion/styled';

interface Props {
  title: string;
  dialogProps: DialogProps;
  loading?: boolean;
  content?: React.ReactNode;
  noText?: string;
  yesText?: string;
  typePrompt?: string;
  useTitleMainColor?: boolean;
  warningIcon?: boolean;

  onConfirm?(e: React.FormEvent<HTMLElement>): void;
  onCancel?(e: React.MouseEvent<HTMLElement>): void;
}

const StyledDialog = styled(Dialog)(
  () => `
    max-width: 331px;
    min-width: 450px;
    margin: 0px auto;
  `,
);

const ErrorIcon = styled(ErrorOutline)(
  () => `
    width: 100%;
    padding-top: 21px;
    font-size: 54px;
    color: #F34436;
  `,
);

export const ConfirmDialog: React.FC<Props> = ({
  title,
  dialogProps,
  loading = false,
  content,
  noText,
  yesText,
  typePrompt,
  onConfirm,
  onCancel,
  useTitleMainColor,
  warningIcon,
}) => {
  const [typePromptValue, setTypePromptValue] = React.useState('');

  return (
    <StyledDialog disableEnforceFocus {...dialogProps}>
      {warningIcon && <ErrorIcon />}
      <DialogTitle
        style={{
          color: useTitleMainColor ? '#F34436' : '',
          paddingTop: warningIcon ? '2px' : '',
          paddingBottom: warningIcon ? '10px' : '',
        }}
      >
        {title}
      </DialogTitle>

      <form
        onSubmit={(e) => {
          e.stopPropagation();
          e.preventDefault();

          onConfirm && onConfirm(e);
          setTypePromptValue('');

          if (dialogProps.onClose) {
            dialogProps.onClose(e, 'escapeKeyDown');
          }
          return false;
        }}
      >
        <DialogContent>
          {typeof content === 'string' ? (
            <DialogContentText>{content}</DialogContentText>
          ) : (
            content
          )}
          {typePrompt && (
            <TextField
              fullWidth
              autoFocus
              onChange={(e) => setTypePromptValue(e.currentTarget.value)}
              helperText={`Please type '${typePrompt}' to confirm this action.`}
              placeholder={`Confirm this action`}
              required
            />
          )}
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            onClick={(e) => {
              if (onCancel) {
                onCancel(e);
              } else if (dialogProps.onClose) {
                dialogProps.onClose(e, 'escapeKeyDown');
              }
            }}
            color="inherit"
            disabled={loading}
          >
            {noText || 'No'}
          </Button>

          {onConfirm && !warningIcon ? (
            <Button
              type="submit"
              color="primary"
              disabled={
                loading ||
                (typePrompt !== undefined &&
                  typePrompt.toLowerCase() !==
                    typePromptValue.trim().toLowerCase())
              }
              autoFocus
            >
              {yesText || 'Yes'}
            </Button>
          ) : (
            onConfirm && (
              <Button
                type="submit"
                style={{ color: '#F34436' }}
                disabled={
                  loading ||
                  (typePrompt !== undefined &&
                    typePrompt.toLowerCase() !==
                      typePromptValue.trim().toLowerCase())
                }
                autoFocus
              >
                {yesText || 'Yes'}
              </Button>
            )
          )}
        </DialogActions>
      </form>
    </StyledDialog>
  );
};
