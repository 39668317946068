import { bbcodeTag } from '../helpers/bbcode_tag_creator';
import tinymce from 'tinymce';

export const CustomPluginEmailSubscription = () => {
  tinymce.PluginManager.add('tomEmailSubscription', (editor, url) => {
    const openEmailSubscription = () => {
      return editor.windowManager.open({
        title: 'Insert Email subscription',
        body: {
          type: 'panel',
          items: [
            {
              type: 'input',
              name: 'channel',
              label: 'Mailing channel:',
              placeholder: 'e.g. marketing',
            },
            {
              type: 'input',
              name: 'link_text',
              label: 'Link text:',
              placeholder: 'e.g. Click here to subscribe to marketing emails',
            },
          ],
        },
        buttons: [
          {
            type: 'cancel',
            text: 'Close',
          },
          {
            type: 'submit',
            text: 'Save',
            primary: true,
          },
        ],
        onSubmit: (api) => {
          const { channel, link_text } = api.getData();

          if (channel.trim() === '' || link_text.trim() === '') {
            tinymce.activeEditor?.windowManager.alert(
              'Mailing channel or link text cannot be empty.',
            );
          } else {
            editor.insertContent(
              bbcodeTag('subscription', link_text, {
                channel: channel.trim(),
              }),
            );
            api.close();
          }
        },
      });
    };

    editor.ui.registry.addButton('tomEmailSubscription', {
      icon: 'email',
      tooltip: 'Insert Email Subscription',
      onAction: () => openEmailSubscription(),
    });
  });
};
