import tinymce from 'tinymce';

/**
 *
 * @param cnt - context
 * @param attach_caption - caption element
 * @param limit - limit of caption
 */
const setMaxLength = (cnt: any, attach_caption: string, limit: number) => {
  if (attach_caption.length > limit) {
    cnt.setData({ attach_caption: attach_caption.slice(0, limit) });
    tinymce.activeEditor?.windowManager.alert(
      `Caption character limit exceeded(${limit} chars)`,
    );
  }
};

export default setMaxLength;
