import { bbcodeTag } from '../helpers/bbcode_tag_creator';
import tinymce from 'tinymce';

export const CustomPluginQuote = () => {
  tinymce.PluginManager.add('tomQuote', (editor, url) => {
    const openQuote = () => {
      return editor.windowManager.open({
        title: 'Insert Quote',
        body: {
          type: 'panel',
          items: [
            {
              type: 'input',
              name: 'quote_name',
              label: 'Name:',
              placeholder: 'E.g: Albert Einstein',
            },
            {
              type: 'textarea',
              name: 'quote_body',
              label: 'Quote:',
              placeholder:
                'E.g: In the middle of every difficulty lies opportunity.',
            },
            {
              type: 'listbox',
              name: 'quote_align',
              label: 'Align',
              items: [
                { text: 'Left', value: 'left' },
                { text: 'Right', value: 'right' },
              ],
            },
          ],
        },
        buttons: [
          {
            type: 'cancel',
            text: 'Close',
          },
          {
            type: 'submit',
            text: 'Save',
            primary: true,
          },
        ],
        onSubmit: (api) => {
          const { quote_name, quote_body, quote_align } = api.getData();

          if (quote_body.trim() === '') {
            tinymce.activeEditor?.windowManager.alert('Quote cannot be empty!');
          } else {
            const attr: any = {};

            if (quote_name.trim().length > 0) {
              attr.name = quote_name.trim();
            }
            attr.align = quote_align;

            editor.insertContent(bbcodeTag('quote', quote_body.trim(), attr));
            api.close();
          }
        },
      });
    };

    editor.ui.registry.addButton('tomQuote', {
      icon: 'quote',
      tooltip: 'Insert Quote',
      onAction: () => openQuote(),
    });
  });
};
