import { ucFirst, getId } from 'lib/Helper/TomHelper';
import { bbcodeTag } from '../helpers/bbcode_tag_creator';
import setMaxLength from '../helpers/set_max_length';
import tinymce from 'tinymce';
import { Config } from 'config';
import { ArticleAttachment } from 'lib/Model/Article';
import { AttachmentType, attachmentTypeToFriendly } from 'lib/Helper/Enums';

function getAttachments(): ArticleAttachment[] {
  return ((window as any).tinymce_attachments as ArticleAttachment[]) ?? [];
}

export const CustomPluginAttach = () => {
  tinymce.PluginManager.add('tomAttach', (editor: any) => {
    const openAttach = () => {
      let attachmentSizes: any[];

      // Image attachment sizes
      const getAttachmentSizes = () => {
        attachmentSizes = Config.ATTACHMENT_SIZES.map((size: any) => ({
          text: ucFirst(size),
          value: size,
        }));

        return attachmentSizes.reverse();
      };

      // Reusable form elements
      const formElements = {
        attachments: {
          type: 'htmlpanel',
          html: '<ul id="attachmentList"></ul>',
          minWidth: '400',
          minHeight: '270',
        },
        video_warning: {
          type: 'htmlpanel', //@todo - To remove after legacy video is disabled
          html: "<p class='video_warning'>Please use JWPlayer plugin to embed videos</p>",
        },
        caption: {
          type: 'input',
          id: 'attach_caption',
          name: 'attach_caption',
          label: 'Caption:',
          multiline: true,
          minHeight: 60,
        },
        size: {
          type: 'listbox',
          id: 'attach_size',
          name: 'attach_size',
          label: 'Size:',
          value: Config.ATTACHMENT_SIZE_DEFAULT,
          items: getAttachmentSizes(),
        },
        align: {
          type: 'listbox',
          id: 'attach_align',
          name: 'attach_align',
          label: 'Align:',
          items: [
            { text: 'Left', value: 'left' },
            { text: 'Right', value: 'right' },
          ],
        },
        mediaType: {
          type: 'input',
          id: 'attach_media',
          name: 'attach_media',
          label: 'Attach Media:',
          multiline: true,
          disabled: true,
          minHeight: 60,
        },
        source: {
          type: 'input',
          id: 'source_url',
          name: 'source_url',
          label: 'Source URL:',
          multiline: true,
          disabled: false,
          minHeight: 60,
        },
        id: {
          label: 'ID:',
          type: 'input',
          id: 'attach_id',
          name: 'attach_id',
          hidden: true,
          disabled: true,
        },
        others: {
          type: 'htmlpanel',
          html: '<div id="otherList"></div>',
        },
        videoLoop: {
          type: 'selectbox',
          name: 'video_loop',
          label: 'Create a video loop (GIF):',
          items: [
            { value: 'false', text: 'No' },
            { value: 'true', text: 'Yes' },
          ],
        },
      };

      // Set Dynamically created elements
      const setAttributes = (el: any, attrs: any) => {
        for (var key in attrs) {
          el.setAttribute(key, attrs[key]);
        }
      };

      // Create component for other
      const createOtherElements = (filteredAttachment: any) => {
        const list = document.getElementById('otherList');
        if (!list) {
          return;
        }

        const mainContainer = document.createElement('div');
        setAttributes(mainContainer, {
          id: `attachment_${filteredAttachment.id}`,
          class: 'ot-otherList_Item',
        });

        const sideContainer = document.createElement('div');
        setAttributes(sideContainer, {
          class: 'ot-otherList_SideContainer',
        });

        const filename = document.createElement('a');
        setAttributes(filename, {
          class: 'ot-otherList_Filename',
          href: filteredAttachment.source_url,
          target: '_blank',
          title: 'View file',
        });
        filename.innerText = filteredAttachment.filename;

        const radioInput = document.createElement('input');
        setAttributes(radioInput, {
          type: 'checkbox',
          class: 'checkbox',
          'data-src': filteredAttachment.source_url,
          'data-name': 'download',
          'data-id': filteredAttachment.id,
        });

        const img = document.createElement('img');
        setAttributes(img, {
          class: 'thumb',
          src: filteredAttachment.src,
        });

        const caption = document.createElement('textarea');
        setAttributes(caption, {
          class: 'ot-otherList_Caption',
          placeholder: 'Add caption here...',
          maxlength: 255,
        });
        caption.innerHTML = filteredAttachment.caption.trim()
          ? filteredAttachment.caption.trim()
          : filteredAttachment.filename;

        sideContainer.appendChild(filename);
        mainContainer.appendChild(radioInput);
        mainContainer.appendChild(img);
        mainContainer.appendChild(sideContainer);
        mainContainer.appendChild(caption);
        list.appendChild(mainContainer);
      };

      // Create component for img / video / audio
      // Create component for img / video / audio
      const createElements = (filteredAttachment: any) => {
        const list = document.getElementById('attachmentList');
        if (!list) {
          return;
        }
        const lis = document.createElement('li');
        setAttributes(lis, {
          id: `attachment_${filteredAttachment.id}`,
          class: 'lis_container',
        });

        const img = document.createElement('img');
        setAttributes(img, {
          id: `attach ${filteredAttachment.id}`,
          class: `attachThumb ${attachmentTypeToFriendly(
            filteredAttachment.type,
          )}`,
          alt: filteredAttachment.caption,
          src: filteredAttachment.url,
          'data-source': filteredAttachment.url,
        });
        const span = document.createElement('span');
        span.innerHTML = filteredAttachment.caption ?? 'No caption';

        lis.appendChild(img);
        lis.appendChild(span);

        list.appendChild(lis);

        return { lis, img };
      };

      // Set attachment for tabs
      const setAttachments = (options: any) => {
        const { newTabName } = options;
        // Clear data when changing tabs
        Object.keys(cnt.getData()).map((key) => cnt.setData({ [key]: '' }));
        cnt.setData({ attach_media: newTabName });

        const setOtherTab = () => {
          if (getAttachments().length === 0) {
            const p = document.createElement('p');
            setAttributes(p, {
              class: `otherList_empty`,
            });
            p.innerText = 'No other files';
            document.getElementById('otherList')?.appendChild(p);
            return;
          }

          getAttachments()
            .filter(
              (attachment: any) => attachment.type !== AttachmentType.IMAGE,
            )
            .map((filteredAttachment: any) =>
              createOtherElements(filteredAttachment),
            );
        };

        const setTab = () => {
          const attachmentType =
            newTabName === 'image'
              ? AttachmentType.IMAGE
              : newTabName === 'audio'
              ? AttachmentType.AUDIO
              : AttachmentType.OTHER;

          // Not other
          getAttachments()
            // Only image and other
            .filter((attachment: any) => attachment.type === attachmentType)
            .map((filteredAttachment: any) => {
              const elements = createElements(filteredAttachment);
              if (!elements) {
                return false;
              }
              const { lis, img } = elements;

              lis.addEventListener('click', () => {
                //Remove selected class
                Array.from(document.querySelectorAll('.lis_container')).forEach(
                  (el) => {
                    el.classList.remove('attachment_selected');
                  },
                );

                cnt.setData({ attach_id: getId(img.id) });
                cnt.setData({ attach_caption: img.getAttribute('alt') });

                let media = img.getAttribute('class');
                media = media?.substring(media.indexOf(' ') + 1) ?? '';
                if (media.length > 6)
                  media = media.substring(0, media.indexOf('u') - 1);
                cnt.setData({ attach_media: media.toLowerCase() });
                cnt.setData({ source_url: img.getAttribute('data-source') });

                lis.classList.add('attachment_selected');
              });
              return false;
            });
        };

        // Change tab init
        newTabName === 'other' ? setOtherTab() : setTab();
      };

      // Dialog configuration
      const _getDialogConfig = () => {
        return {
          title: 'Insert Attachment',
          size: 'medium',
          body: {
            type: 'tabpanel',
            tabs: [
              {
                name: 'image',
                title: 'Images',
                items: [
                  formElements.attachments,
                  formElements.caption,
                  formElements.size,
                  formElements.align,
                  formElements.mediaType,
                  formElements.source,
                  formElements.id,
                ],
              },
              {
                name: 'audio',
                title: 'Audio',
                items: [
                  formElements.attachments,
                  formElements.caption,
                  formElements.mediaType,
                  formElements.source,
                  formElements.id,
                ],
              },
              {
                name: 'other',
                title: 'Other',
                items: [formElements.others],
              },
            ],
          },
          buttons: [
            {
              type: 'cancel',
              text: 'Close',
            },
            {
              type: 'submit',
              text: 'Insert',
              primary: true,
            },
          ],
          onTabChange: (api: any, details: any) => setAttachments(details),
          onChange: (api: any) => {
            const { attach_caption } = api.getData();
            const caption_limit = 255;

            setMaxLength(cnt, attach_caption, caption_limit);
          },
          onSubmit: (api: any) => {
            const { attach_caption, attach_media, video_loop } = api.getData();

            if (attach_media !== 'other' && attach_caption.length === 0) {
              tinymce.activeEditor?.windowManager.alert(
                'Attachments require captions.',
              );
              return;
            }

            if (attach_media === 'other') {
              const others = [
                ...document.querySelectorAll('.ot-otherList_Item'),
              ]
                .filter(
                  (other) =>
                    !!other.querySelector('#otherList .checkbox:checked'),
                )
                .map((other) => {
                  const input = other.querySelector('input');
                  if (!input) {
                    return false;
                  }
                  const caption =
                    other.getElementsByTagName('textarea')[0].value;

                  const attr = { id: input.dataset.id };
                  const sl = input.dataset.name;

                  return bbcodeTag(sl, caption.trim(), attr);
                });

              if (others.length > 0) {
                editor.insertContent(bbcodeTag('downloads', others.join('')));
                api.close();
              } else {
                tinymce.activeEditor?.windowManager.alert(
                  'Please select at least one file.',
                );
              }

              return;
            }

            const { attach_id, attach_align, attach_size, source_url } =
              api.getData();

            if (!attach_id) {
              tinymce.activeEditor?.windowManager.alert(
                'Kindly select an attachment to insert',
              );
            } else {
              const getBBcode = (attach_media: any) => {
                // Insert bbcode into editor
                switch (attach_media) {
                  case 'image':
                    return bbcodeTag('attach', attach_caption, {
                      id: attach_id,
                      size: attach_size,
                      align: attach_align,
                      type: attach_media,
                    });

                  case 'video':
                    return bbcodeTag('attach', attach_caption, {
                      id: attach_id,
                      type:
                        attach_media + (video_loop === 'true' ? '_loop' : ''),
                    });

                  case 'audio':
                    return bbcodeTag('audio', attach_caption, {
                      id: attach_id,
                      src: source_url,
                    });

                  default:
                  // console.error("This attachment type cannot be added inline.");
                }
              };

              const bbCode = getBBcode(attach_media);
              if (bbCode) {
                editor.insertContent(bbCode);
              }
              api.close();
            }
          },
          onClose: () => {
            //Clear attachmentSizes value;
            attachmentSizes = [];
          },
        };
      };

      // Init dialog
      const cnt = editor.windowManager.open(_getDialogConfig());

      // Init
      setAttachments({ newTabName: 'image' });
    };

    editor.ui.registry.addButton('tomAttach', {
      icon: 'paper-clip',
      tooltip: 'Insert Attachment',
      onAction: () => openAttach(),
    });
  });
};
