const env = process.env;

export const Config = {
  DEBUG: env.NODE_ENV ? env.NODE_ENV === 'development' : true,
  API_BASE_URL: env.REACT_APP_API_BASE_URL || 'https://tomcms.preeodev.com',
  //API_BASE_URL: env.REACT_APP_API_BASE_URL || 'http://tomcms.local',
  TINYMCE_API_KEY: 'ubqck8cb700nwqv6enwr6j3t5zwdrmad85v4yz843w9laxpb',
  ATTACHMENT_SIZES: ['small', 'medium', 'large'],
  ATTACHMENT_SIZE_DEFAULT: 'large',
  IFRAME_BLACKLIST: ['facebook.com', 'youtube.com', 'tiktok.com'],
  VERSION: 'Prototype Phase 0.02',
};
