import { bbcodeTag } from '../helpers/bbcode_tag_creator';
import tinymce from 'tinymce';

export const CustomPluginJwPlayer = () => {
  tinymce.PluginManager.add('tomJwPlayer', (editor, url) => {
    const openJwPlayer = () => {
      return editor.windowManager.open({
        title: 'Insert JwPlayer Video',
        body: {
          type: 'panel',
          items: [
            {
              type: 'input',
              name: 'video_id',
              label: 'Media ID:',
              placeholder: 'Format: 34g4XCRw',
            },
            {
              type: 'input',
              name: 'caption',
              label: 'Caption:',
              placeholder:
                'Craft the perfect caption to captivate your audience',
            },
            {
              type: 'htmlpanel',
              html: "<a href='https://dashboard.jwplayer.com/p/ArjU4xDT/media' target='_blank' id='tiny-jwplayer-plugin'>Click here to open JWPlayer dashboard</a>",
            },
          ],
        },
        buttons: [
          {
            type: 'cancel',
            text: 'Close',
          },
          {
            type: 'submit',
            text: 'Save',
            primary: true,
          },
        ],
        onSubmit: (api) => {
          const { video_id, caption } = api.getData();

          if (video_id.trim() === '' || caption.trim() === '') {
            tinymce.activeEditor?.windowManager.alert(
              'Video ID and Caption are required.',
            );
          } else {
            editor.insertContent(
              bbcodeTag('jwplayer', caption.trim(), {
                id: video_id.trim(),
              }),
            );
            api.close();
          }
        },
      });
    };

    editor.ui.registry.addButton('tomJwPlayer', {
      icon: 'embed',
      tooltip: 'Insert JwPlayer Video',
      onAction: () => openJwPlayer(),
    });
  });
};
