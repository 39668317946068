import { bbcodeTag } from '../helpers/bbcode_tag_creator';
import setMaxLength from '../helpers/set_max_length';
import tinymce from 'tinymce';

export const CustomPluginAudio = () => {
  tinymce.PluginManager.add('tomAudio', (editor, url) => {
    const openAudio = () => {
      const cnt = editor.windowManager.open({
        title: 'Insert Audio',
        body: {
          type: 'panel',
          items: [
            {
              type: 'input',
              name: 'audio_url',
              label: 'URL:',
              placeholder: 'E.g: http://',
            },
            {
              type: 'input',
              name: 'attach_caption',
              label: 'Caption:',
              placeholder: 'E.g: Beethoven last symphony',
            },
          ],
        },
        buttons: [
          {
            type: 'cancel',
            text: 'Close',
          },
          {
            type: 'submit',
            text: 'Save',
            primary: true,
          },
        ],
        onChange: (api) => {
          const { attach_caption } = api.getData();
          const caption_limit = 255;

          setMaxLength(cnt, attach_caption, caption_limit);
        },
        onSubmit: (api) => {
          const { audio_url, attach_caption } = api.getData();

          if (audio_url.trim() === '' || attach_caption.trim() === '') {
            tinymce.activeEditor?.windowManager.alert(
              'URL or Caption cannot be left empty',
            );
          } else {
            editor.insertContent(
              bbcodeTag('audio', attach_caption.trim(), {
                src: audio_url.trim(),
              }),
            );
            api.close();
          }
        },
      });
    };

    editor.ui.registry.addButton('tomAudio', {
      icon: 'audio',
      tooltip: 'Insert Audio',
      onAction: () => openAudio(),
    });
  });
};
