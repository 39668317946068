import { UpdateNotification } from 'layouts/App/components';
import * as React from 'react';
import { Link, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { AuthHandler } from './components/AuthHandler';
import { LoginForm } from './components/LoginForm';
import { styled } from '@mui/system';
import { ForgotPassword } from './components/ForgotPassword';
import { ResetPassword } from './components/ResetPassword';

interface Props {
  waitingServiceWorker: ServiceWorker | null;
  updateServiceWorker(worker: ServiceWorker | null, fromBanner: boolean): void;
}

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  position: 'relative',
  justifyContent: 'center',
  backgroundSize: 'cover',
  paddingBottom: 80,
  margin: '0px auto',
}));

const LogoContainer = styled('div')(({ theme }) => ({
  textAlign: 'center',
  margin: theme.spacing(2, 0, 1, 0),
  [theme.breakpoints.up('sm')]: {
    margin: theme.spacing(6, 0, 4, 0),
  },
}));

const AuthContainer = styled('div')(({ theme }) => ({
  width: 'auto',
  [theme.breakpoints.up('sm')]: {
    width: 400,
  },
}));

const StyledLogo = styled('img')(({ theme }) => ({
  height: 30,
}));

export const AuthLayout: React.FC<Props> = ({
  waitingServiceWorker,
  updateServiceWorker,
}) => {
  const { hash, pathname } = useLocation();

  const isLogoutAction = hash === '#logout';

  return (
    <React.Fragment>
      {Boolean(waitingServiceWorker) && (
        <UpdateNotification
          updateServiceWorker={() =>
            updateServiceWorker(waitingServiceWorker, true)
          }
        />
      )}

      <Container>
        <div>
          <LogoContainer>
            <Link to="/">{getLogo()}</Link>
          </LogoContainer>

          <AuthContainer>
            <Switch>
              <Route path="/auth">
                <AuthHandler />
              </Route>
              <Route path="/login">
                <LoginForm />
              </Route>
              <Route path="/forgot-password">
                <ForgotPassword />
              </Route>
              <Route
                path="/reset-password/:code"
                render={(componentProps) => (
                  <div>
                    <ResetPassword {...componentProps} />
                  </div>
                )}
              />
              <Route>
                <Redirect
                  to={`/login${isLogoutAction ? hash : `?r=${pathname}`}`}
                />
              </Route>
            </Switch>
          </AuthContainer>
        </div>
      </Container>
    </React.Fragment>
  );

  function getLogo() {
    return <StyledLogo src="/logo-tom.svg" alt="TimesOfMalta" />;
  }
};
