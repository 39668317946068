import { bbcodeTag } from '../helpers/bbcode_tag_creator';
import tinymce from 'tinymce';

export const CustomPluginTweet = () => {
  tinymce.PluginManager.add('tomTweet', (editor, url) => {
    const openTweet = () => {
      return editor.windowManager.open({
        title: 'Embed Tweet',
        body: {
          type: 'panel',
          items: [
            {
              type: 'input',
              name: 'tweet_url',
              placeholder: 'Paste Tweet URL here',
            },
            {
              type: 'htmlpanel',
              html: '<div class="preview-text" id="tweet_preview" style="min-height:400px">Preview</div>',
            },
          ],
        },
        buttons: [
          {
            type: 'cancel',
            text: 'Close',
          },
          {
            type: 'submit',
            text: 'Save',
            primary: true,
          },
        ],
        onSubmit: (api) => {
          const { tweet_url } = api.getData();
          try {
            const trimmedUrl = new URL(tweet_url.trim());
            if (!trimmedUrl || !trimmedUrl.href) {
              return;
            }

            const splitUrl = trimmedUrl.href.split('/');
            if (!splitUrl) {
              return;
            }

            const id = splitUrl.pop()?.split('?').shift() ?? '';

            if (!id) {
              return;
            }

            editor.insertContent(
              bbcodeTag('twitter', '', {
                id: id,
                link: trimmedUrl.href,
              }),
            );
          } catch (ex) {
            // Do nothing with exception
          } finally {
            api.close();
          }

          api.close();
        },
        onChange: (api) => {
          const { tweet_url } = api.getData();

          const preview = document.getElementById('tweet_preview');
          if (!preview) {
            return;
          }

          preview.innerHTML = 'Type in a valid tweet URL';
          try {
            const trimmedUrl = new URL(tweet_url.trim());
            if (!trimmedUrl || !trimmedUrl.href) {
              return;
            }

            preview.innerHTML =
              '<blockquote class="twitter-tweet" lang="en-gb">' +
              '<p lang="en" dir="ltr">' +
              '<a href="' +
              trimmedUrl.href +
              '"></a></p></blockquote>';
            const twttr = (window as any).twttr;
            if (!twttr) {
              return;
            }
            twttr.widgets.load(document.getElementById('tweet_preview'));
          } catch (ex) {
            // Do nothing with exception
          }
        },
      });
    };
    editor.ui.registry.addButton('tomTweet', {
      icon: 'twitter',
      tooltip: 'Insert Tweet',
      onAction: () => openTweet(),
    });
  });
};
