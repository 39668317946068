import * as React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Chip,
  Drawer,
  Grid,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import { DelayedLinearProgress, SnackbarContext } from 'components';
import { ExpandMore } from '@mui/icons-material';
import { User, getNewUser } from 'lib/Model/User';
import { createUser, fetchUser, updateUser } from 'lib/Service/Users';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { DateTimeFormat } from 'config';

interface Props {
  user: User | undefined;
  open: boolean;
  onClose: () => void;
  onSaved: (savedUser: User) => void;
}

const StyledAvatar = styled(Avatar)(
  ({ theme }) => `
      width: 60px;
      height: 60px;
      margin-right: ${theme.spacing(1)};
  `,
);

// TODO: change to an API call
const rolesList = [
  'Publishing Manager',
  'Journalist',
  'UX Manager',
  'Online Editor',
];

export const UserDrawer: React.FC<Props> = ({
  user: selectedUser,
  onClose,
  open,
  onSaved,
}) => {
  const theme = useTheme();
  const snackbar = React.useContext(SnackbarContext);

  const [loading, setLoading] = React.useState(false);
  const [editingUser, setEditingUser] = React.useState<User>(
    selectedUser ?? getNewUser(),
  );

  React.useEffect(() => {
    setEditingUser(selectedUser ?? getNewUser());
  }, [selectedUser, setEditingUser]);

  const fetchData = React.useCallback(() => {
    if (!selectedUser) {
      return;
    }
    setLoading(true);
    fetchUser(selectedUser.id)
      .then((d) => {
        setLoading(false);
        setEditingUser(d);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [setLoading, selectedUser, setEditingUser]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Drawer
      open={open}
      anchor="right"
      onClose={() => {
        onClose();
      }}
    >
      <DelayedLinearProgress loading={loading} />
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          setLoading(true);
          try {
            if (selectedUser) {
              await updateUser(editingUser);
            } else {
              await createUser(editingUser);
            }
            onSaved(editingUser);
            onClose();
            snackbar.success('Changes saved successfully');
          } catch (error) {
            if (error instanceof AxiosError && error.response?.data.message) {
              snackbar.error(error.response.data.message);
              return;
            }
            snackbar.error('Unknown error while saving');
          } finally {
            setLoading(false);
          }
        }}
      >
        <Box style={{ minWidth: '30vw', padding: 16 }}>
          <Grid container spacing={1} style={{ maxWidth: 800 }}>
            <Grid
              item
              xs={12}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <StyledAvatar src={editingUser?.photo_url} />
              <Box>
                <Typography variant="h6">
                  {editingUser?.id === -1 ? 'Add' : 'Edit'} user
                </Typography>
                {editingUser?.last_login && (
                  <Typography
                    variant="caption"
                    style={{ position: 'relative', top: -8 }}
                  >
                    Last login{' '}
                    {format(
                      editingUser.last_login as Date,
                      DateTimeFormat.MEDIUM,
                    )}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                fullWidth
                label="First name"
                InputLabelProps={{ shrink: Boolean(editingUser?.first_name) }}
                onChange={(e) => {
                  if (!editingUser) {
                    return;
                  }
                  setEditingUser((r) => ({
                    ...r!,
                    first_name: e.target?.value ?? '',
                  }));
                }}
                value={editingUser?.first_name ?? ''}
                required
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Last name"
                InputLabelProps={{ shrink: Boolean(editingUser?.last_name) }}
                onChange={(e) => {
                  if (!editingUser) {
                    return;
                  }
                  setEditingUser((r) => ({
                    ...r!,
                    last_name: e.target?.value ?? '',
                  }));
                }}
                value={editingUser?.last_name ?? ''}
                required
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email address"
                InputLabelProps={{
                  shrink: Boolean(editingUser?.email),
                }}
                onChange={(e) => {
                  if (!editingUser) {
                    return;
                  }
                  setEditingUser((r) => ({
                    ...r!,
                    email: e.target?.value ?? '',
                  }));
                }}
                value={editingUser?.email ?? ''}
                required
                type="email"
                margin="none"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Change password"
                value={editingUser?.password ?? ''}
                InputLabelProps={{
                  shrink: Boolean(editingUser?.password),
                }}
                onChange={(e) => {
                  if (!editingUser) {
                    return;
                  }
                  setEditingUser((r) => ({
                    ...r!,
                    password: e.target?.value ?? '',
                  }));
                }}
                type="password"
                margin="none"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                disabled={!Boolean(editingUser?.password)}
                fullWidth
                label="Confirm password"
                value={editingUser?.password_confirmation ?? ''}
                InputLabelProps={{
                  shrink: Boolean(editingUser?.password_confirmation),
                }}
                onChange={(e) => {
                  if (!editingUser) {
                    return;
                  }
                  setEditingUser((r) => ({
                    ...r!,
                    password_confirmation: e.target?.value ?? '',
                  }));
                }}
                type="password"
                margin="none"
              />
            </Grid>

            <Grid item xs={12}>
              <Accordion style={{ marginTop: theme.spacing(2) }} expanded>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  Roles{' '}
                  <Chip
                    label={`${editingUser?.roles?.length ?? '0'}`}
                    size="small"
                    style={{ marginLeft: theme.spacing(0.5) }}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    {rolesList.map((p, index) => {
                      // TODO: change to role ids
                      const isRoleSelected =
                        (editingUser?.roles?.filter((sr) => sr.name === p)
                          .length ?? 0) > 0;
                      return (
                        <Chip
                          color={isRoleSelected ? 'primary' : 'default'}
                          key={`tag_${index}`}
                          style={{ marginRight: theme.spacing(0.5) }}
                          label={p}
                        />
                      );
                    })}
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: theme.spacing(1),
              paddingTop: theme.spacing(1),
            }}
          >
            <Button
              variant="text"
              type="submit"
              color="primary"
              disabled={loading}
            >
              Save
            </Button>{' '}
            <Button
              variant="text"
              type="button"
              color="inherit"
              onClick={() => onClose()}
              disabled={loading}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
    </Drawer>
  );
};
