import { bbcodeTag } from '../helpers/bbcode_tag_creator';
import tinymce from 'tinymce';

export const CustomPluginRelated = () => {
  tinymce.PluginManager.add('tomRelated', (editor, url) => {
    const openRelated = () => {
      return editor.windowManager.open({
        title: 'Insert Related Stories',
        body: {
          type: 'panel',
          items: [
            {
              type: 'input',
              name: 'rel_story_title1',
              label: 'Article Title 1:',
              placeholder: '',
            },
            {
              type: 'input',
              name: 'rel_story_url1',
              label: 'URL 1:',
              placeholder: '',
            },
            {
              type: 'input',
              name: 'rel_story_title2',
              label: 'Article Title 2:',
              placeholder: '',
            },
            {
              type: 'input',
              name: 'rel_story_url2',
              label: 'URL 2:',
              placeholder: '',
            },
          ],
        },
        buttons: [
          {
            type: 'cancel',
            text: 'Close',
          },
          {
            type: 'submit',
            text: 'Save',
            primary: true,
          },
        ],
        onSubmit: (api) => {
          const {
            rel_story_title1,
            rel_story_title2,
            rel_story_url1,
            rel_story_url2,
          } = api.getData();

          let arts: any[] = [];
          let stories: any[] = [];
          let obj1 = { title: rel_story_title1, url: rel_story_url1 };
          let obj2 = { title: rel_story_title2, url: rel_story_url2 };

          arts.push(obj1, obj2);

          arts.forEach((art, key) => {
            if (!(art.title.trim() === '' || art.url.trim() === '')) {
              stories.push(
                bbcodeTag('related_item', art.title.trim(), {
                  url: art.url.trim(),
                }),
              );
            }
          });

          if (stories.length <= 0) {
            tinymce.activeEditor?.windowManager.alert(
              'You need to add at least one related story.',
            );
          } else {
            editor.insertContent(bbcodeTag('related', stories.join('')));
            api.close();
          }
        },
      });
    };

    editor.ui.registry.addButton('tomRelated', {
      icon: 'related',
      tooltip: 'Insert Related Stories',
      onAction: () => openRelated(),
    });
  });
};
