import { Typography, useTheme } from '@mui/material';
import brandLogo from '../../layouts/App/assets/timesofmalta.png';
import * as React from 'react';
import {
  StyledWrapper,
  StyledLogo,
  SuccessButton,
  StyledDivider,
  StyledPrimaryLink,
} from './StyledComponents';

export const RefreshRequired: React.FC = () => {
  const theme = useTheme();

  return (
    <StyledWrapper>
      <a href="https://exp42.com" target="_blank" rel="noopener noreferrer">
        <StyledLogo src={brandLogo} alt="TimesOfMalta" />
      </a>
      <Typography color="inherit" align="center" component="div">
        <Typography variant="h5" gutterBottom>
          Reload required
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          A newer version of TOM CMS is available.
        </Typography>
        <div style={{ margin: theme.spacing(2, 0) }}>
          <SuccessButton
            color="primary"
            variant="contained"
            onClick={() => document.location.reload()}
          >
            Reload for latest version
          </SuccessButton>
        </div>
        <StyledDivider />
        <Typography>
          If that doesn't work, please try refreshing manually
          <br />
          <em>Ctrl + Shift + R (Windows)</em> or{' '}
          <em>⌘ Cmd + Shift + R (Mac).</em>
          <br /> <br />
          Kindly{' '}
          <StyledPrimaryLink
            href="https://talexio.com/contact-us/?src=reload-required"
            target="_blank"
            rel="noopener noreferrer"
          >
            contact us
          </StyledPrimaryLink>{' '}
          if you continue seeing this message after reloading
        </Typography>
      </Typography>
    </StyledWrapper>
  );
};
