import * as React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Chip,
  Drawer,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { DelayedLinearProgress, SnackbarContext } from 'components';
import { ExpandMore } from '@mui/icons-material';
import { Role, getNewRole } from 'lib/Model/Role';
import { createRole, updateRole } from 'lib/Service/Roles';
import { AxiosError } from 'axios';
import { generateSeededColour } from 'lib';
import { styled } from '@mui/system';

interface Props {
  role: Role | undefined;
  open: boolean;
  onClose: () => void;
  onSaved: (savedRole: Role) => void;
}

const StyledAvatar = styled(Avatar)(
  ({ theme }) => `
      width: 60px;
      height: 60px;
      margin-right: ${theme.spacing(1)};
  `,
);

// TODO: convert to api call
const permissionsList = [
  'Pages',
  'Articles',
  'Publish article',
  'Classifieds',
  'Tags',
  'Navigation',
  'Breaking news',
  'Media library',
];

// TODO: convert to api call
const tagList = [
  'test',
  'news',
  'national',
  'local',
  'sports',
  'europe',
  'testing',
];

export const RoleDrawer: React.FC<Props> = ({
  role: selectedRole,
  onClose,
  open,
  onSaved,
}) => {
  const snackbar = React.useContext(SnackbarContext);

  const [loading, setLoading] = React.useState(false);
  const [editingRole, setEditingRole] = React.useState<Role>(
    selectedRole ?? getNewRole(),
  );

  React.useEffect(() => {
    setEditingRole(selectedRole ?? getNewRole());
  }, [selectedRole, setEditingRole]);

  return (
    <Drawer
      open={open}
      anchor="right"
      onClose={() => {
        onClose();
      }}
    >
      <DelayedLinearProgress loading={loading} />
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          e.stopPropagation();

          setLoading(true);
          try {
            if (selectedRole) {
              await updateRole(editingRole);
            } else {
              await createRole(editingRole);
            }
            onSaved(editingRole);
            onClose();
            snackbar.success('Changes saved successfully');
          } catch (error) {
            if (error instanceof AxiosError && error.response?.data.message) {
              snackbar.error(error.response.data.message);
              return;
            }
            snackbar.error('Unknown error while saving');
          } finally {
            setLoading(false);
          }
        }}
      >
        <Box style={{ minWidth: '30vw', padding: 16 }}>
          <Box
            style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}
          >
            <StyledAvatar
              style={{
                backgroundColor: `#${generateSeededColour({
                  seed: editingRole?.name.length
                    ? editingRole.name
                    : 'New role',
                })}`,
              }}
            >
              &nbsp;
            </StyledAvatar>
            <Typography variant="h6">
              {editingRole?.id === -1 ? 'Add' : 'Edit'} role
            </Typography>
          </Box>
          <TextField
            autoFocus
            fullWidth
            label="Role name"
            InputLabelProps={{ shrink: Boolean(editingRole?.name) }}
            onChange={(e) => {
              if (!editingRole) {
                return;
              }
              setEditingRole((r) => ({
                ...r!,
                name: e.target?.value ?? '',
              }));
            }}
            value={editingRole?.name ?? ''}
            required
            margin="normal"
          />
          <Accordion style={{ marginTop: 16 }}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              Permissions{' '}
              <Chip
                label={`${editingRole?.permissions?.length ?? '0'}/${
                  permissionsList.length
                }`}
                size="small"
                style={{ marginLeft: 4 }}
              />
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {permissionsList.map((p, index) => {
                  const thisPermission = editingRole?.permissions?.find(
                    (rp) => rp.name === p, // TODO: check comparison based on what we receive from permissions
                  );
                  return (
                    <ListItem key={`permission_${index}`}>
                      <ListItemText>{p}</ListItemText>
                      <ListItemSecondaryAction>
                        <ToggleButtonGroup
                          exclusive
                          onChange={(e, val) => {
                            setEditingRole((r) => {
                              const newRolePermissions =
                                r?.permissions?.slice() ?? [];
                              const toUpdate =
                                thisPermission &&
                                newRolePermissions.find(
                                  (update) =>
                                    update.name === thisPermission.name, // TODO: check comparisons
                                );
                              if (toUpdate) {
                                // toUpdate.accessLevel = val;
                              } else {
                                newRolePermissions.push({
                                  id: -1, // TODO: change how this works
                                  name: p, // TODO: fix this based on api
                                  // accessLevel: val,
                                });
                              }
                              return {
                                ...r,
                                // rolePermissions: newRolePermissions,
                              };
                            });
                          }}
                          color="primary"
                          size="small"
                          value={'none'} // TODO: fix this based on api
                        >
                          <ToggleButton value="none">None</ToggleButton>
                          <ToggleButton value="read">Read</ToggleButton>
                          <ToggleButton value="write">Write</ToggleButton>
                        </ToggleButtonGroup>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              Article tag filters{' '}
              <Chip
                label={`${editingRole?.tags?.length ?? '0'}`}
                size="small"
                style={{ marginLeft: 4 }}
              />
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                {tagList.map((p, index) => {
                  const isTagSelected = editingRole?.tags?.includes(p);
                  return (
                    <Chip
                      color={isTagSelected ? 'primary' : 'default'}
                      key={`tag_${index}`}
                      style={{ marginRight: 4 }}
                      label={p}
                    />
                  );
                })}
              </Box>
            </AccordionDetails>
          </Accordion>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 8,
              paddingTop: 8,
            }}
          >
            <Button
              type="submit"
              variant="text"
              color="primary"
              disabled={loading}
            >
              Save
            </Button>{' '}
            <Button
              type="button"
              variant="text"
              color="inherit"
              onClick={() => onClose()}
              disabled={loading}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
    </Drawer>
  );
};
