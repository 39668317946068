import * as React from 'react';
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  IconButton,
  InputAdornment,
  Link,
  Skeleton,
  TextField,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import {
  PageableTable,
  PageableTableRow,
  PopperDropdownMenu,
  SnackbarContext,
} from 'components';
import { PageableTableHeader } from 'components/PageableTable/components';
import { CheckCircle, CloseRounded, Search } from '@mui/icons-material';
import { AuthorDrawer } from './AuthorDrawer';
import { Author, AuthorsResponse } from 'lib/Model/Author';
import { deleteAuthor, fetchAuthors } from 'lib/Service/Authors';
import { useDebounce } from 'hooks';
import { ConfirmDialog } from 'components/ConfirmDialog';
import { AxiosError } from 'axios';
import styled from '@emotion/styled';

const StyledLink = styled(Link)(
  () => `
    margin-bottom: 0;
    text-transform: none;
    font-weight: 500;
    text-decoration: none;
    font-size: 120%;
    cursor: pointer;
  `,
);

export const AuthorsTable: React.FC = () => {
  const theme = useTheme();
  const snackbar = React.useContext(SnackbarContext);

  const [textSearch, setTextSearch] = React.useState('');
  const [selectedAuthor, setSelectedAuthor] = React.useState<
    Author | undefined
  >();
  const [toDelete, setToDelete] = React.useState<Author | undefined>();

  const debouncedTextSearch = useDebounce(textSearch, 500);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [queryResponse, setQueryResponse] = React.useState<
    AuthorsResponse | undefined
  >();

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const fetchData = React.useCallback(() => {
    setLoading(true);
    fetchAuthors(debouncedTextSearch, pageNumber + 1, pageSize)
      .then((d) => {
        setLoading(false);
        setQueryResponse(d);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [setLoading, setQueryResponse, pageNumber, pageSize, debouncedTextSearch]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = React.useMemo(() => {
    return [
      {
        key: 'avatar',
        label: '',
        sortable: false,
        props: {
          style: { width: 60 },
        },
      },
      {
        key: 'author',
        label: 'Author',
        sortable: false,
      },
      {
        key: 'active',
        label: 'Active',
        sortable: false,
        props: {
          style: {
            width: 50,
            textAlign: 'center',
          },
        },
      },
      {
        key: 'visible',
        label: 'Visible',
        sortable: false,
        props: {
          style: {
            width: 50,
            textAlign: 'center',
          },
        },
      },
      {
        key: 'actions',
        label: '',
        sortable: false,
        props: {
          style: {
            width: 50,
          },
        },
      },
    ] as PageableTableHeader[];
  }, []);

  const [rows, setRows] = React.useState<PageableTableRow[]>([]);

  React.useEffect(
    () =>
      setRows(
        queryResponse?.data?.map((u) => {
          return {
            key: `author_${u.id}`,
            cells: [
              {
                key: 'avatar',
                display: <Avatar src={u.picture} />,
              },
              {
                key: 'author',
                display: (
                  <React.Fragment>
                    <StyledLink
                      onClick={() => {
                        setSelectedAuthor(u);
                      }}
                    >
                      {u.display_name}
                    </StyledLink>
                    <Typography variant="caption" style={{ display: 'block' }}>
                      {u.email}
                    </Typography>
                  </React.Fragment>
                ),
              },
              {
                key: 'active',
                props: {
                  style: {
                    textAlign: 'center',
                  },
                },
                display: (
                  <React.Fragment>
                    {u.active ? (
                      <CheckCircle color="success" />
                    ) : (
                      <CloseRounded color="disabled" />
                    )}
                  </React.Fragment>
                ),
              },
              {
                key: 'visible',
                props: {
                  style: {
                    textAlign: 'center',
                  },
                },
                display: (
                  <React.Fragment>
                    {u.visible ? (
                      <CheckCircle color="success" />
                    ) : (
                      <CloseRounded color="disabled" />
                    )}
                  </React.Fragment>
                ),
              },
              {
                key: 'actions',
                props: {
                  style: {
                    textAlign: 'right',
                  },
                },
                display: (
                  <PopperDropdownMenu
                    menuItems={[
                      {
                        label: 'Edit',
                        onClick: () => {
                          setSelectedAuthor(u);
                        },
                      },
                      {
                        label: 'Unlink 2FA', // TODO: api endpoints for this
                      },
                      {
                        label: 'Revoke access', // TODO: api endpoints for this
                      },
                      {
                        label: 'Delete',
                        onClick: () => {
                          setToDelete(u);
                        },
                      },
                    ]}
                  />
                ),
              },
            ],
          } as PageableTableRow;
        }) ?? [],
      ),
    [queryResponse, setRows, theme],
  );

  return (
    <Card>
      <CardHeader
        title="Authors"
        action={
          <Button
            color="primary"
            onClick={() => {
              setSelectedAuthor(undefined);
              setDrawerOpen(true);
            }}
          >
            Add author
          </Button>
        }
      />
      <Toolbar style={{ marginBottom: theme.spacing(1) }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Type here to find authors.."
          value={textSearch}
          onChange={(e) => setTextSearch(e.target.value ?? '')}
          autoFocus
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>

      {loading ? (
        <Skeleton variant="rectangular" height={150} />
      ) : (
        <PageableTable
          columns={columns}
          rows={rows}
          pageNumber={pageNumber}
          rowCount={queryResponse?.meta?.total ?? rows.length}
          onChangePageSize={setPageSize}
          onChangePage={setPageNumber}
        />
      )}

      <AuthorDrawer
        open={Boolean(selectedAuthor) || drawerOpen}
        onClose={() => {
          setSelectedAuthor(undefined);
          setDrawerOpen(false);
        }}
        author={selectedAuthor}
        onSaved={() => {
          setSelectedAuthor(undefined);
          setDrawerOpen(false);
          fetchData();
        }}
      />

      {toDelete && (
        <ConfirmDialog
          dialogProps={{
            open: true,
          }}
          typePrompt={`${toDelete?.display_name ?? 'Delete'}`}
          title="Are you sure you want to proceed?"
          yesText="Delete"
          noText="Cancel"
          onCancel={() => setToDelete(undefined)}
          onConfirm={async () => {
            try {
              await deleteAuthor(toDelete);
              snackbar.success('Author deleted');
              fetchData();
            } catch (error) {
              if (error instanceof AxiosError && error.response?.data.message) {
                snackbar.error(error.response.data.message);
                return;
              }
              snackbar.error('Unknown error while saving');
            } finally {
              setToDelete(undefined);
            }
          }}
        />
      )}
    </Card>
  );
};
