/**
 * Resolves a numeric id from a string id.
 *
 * @param string id - The string to find an id in
 * @param string suffix - Id is a suffix in the string
 * @return int - The numeric id
 */
const getId = function (id: string, suffix?: string) {
  if ('string' === typeof suffix) {
    const matched = id.match(/.*_(.*?)$/);
    if (matched) {
      return matched[1];
    }
  }

  if ('string' === typeof id) {
    const matched = id.match(/\D*?(\d+)$/);
    if (matched) {
      return matched[1];
    }
  }

  return id;
};

/**
 * Uppercases first character of a string.
 *
 * @param string text - The string to uppercase
 * @return string - The uppercased string
 */
const ucFirst = function (text: string) {
  return text.charAt(0).toUpperCase() + text.substr(1);
};

export { ucFirst, getId };
