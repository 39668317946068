import { AppLayout, AuthLayout } from 'layouts';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import Cookies from 'js-cookie';
import { getUserData } from 'lib/Helper/Auth';

export const LayoutSwitcher: React.FC = () => {
  const { pathname } = useLocation();
  const [waitingServiceWorker, setWaitingServiceWorker] =
    React.useState<ServiceWorker | null>(null);

  const onServiceWorkerUpdate = React.useCallback(
    function onServiceWorkerUpdate(registration: ServiceWorkerRegistration) {
      if (Cookies.get('authUser')) {
        updateServiceWorker(registration.waiting);
      } else {
        setWaitingServiceWorker(registration.waiting);
      }
    },
    [],
  );

  React.useEffect(() => {
    serviceWorkerRegistration.register({ onUpdate: onServiceWorkerUpdate });
  }, [onServiceWorkerUpdate]);

  const checkForUpdates = React.useCallback(function checkForUpdates() {
    navigator.serviceWorker?.getRegistrations().then((registrationsArray) => {
      const sw = registrationsArray[0];

      if (sw) {
        sw.update();
        updateServiceWorker(sw.waiting);
      }
    });
  }, []);

  React.useEffect(() => {
    checkForUpdates();
  }, [pathname, checkForUpdates]);

  React.useEffect(() => {
    setInterval(() => {
      checkForUpdates();
    }, 1000 * 60 * 30 /* every 30 mins */);
  }, [checkForUpdates]);

  const authUser = getUserData();
  const location = useLocation();
  const isAuth = location.pathname.indexOf('/auth') !== -1;

  const props = { waitingServiceWorker, updateServiceWorker };

  return authUser !== null && !isAuth ? (
    <AppLayout {...props} />
  ) : (
    <AuthLayout {...props} />
  );

  function updateServiceWorker(
    sw: ServiceWorker | null | undefined,
    fromBanner: boolean = false,
  ) {
    if (!sw) {
      return;
    }

    sw.postMessage({ type: 'SKIP_WAITING' });

    if (Cookies.get('authUser') || fromBanner) {
      window.location.reload();
    }
  }
};
