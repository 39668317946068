import { bbcodeTag } from '../helpers/bbcode_tag_creator';
import { ArticleAttachment } from 'lib/Model/Article';
import tinymce from 'tinymce';

function getAttachments(): ArticleAttachment[] {
  return ((window as any).tinymce_attachments as ArticleAttachment[]) ?? [];
}

export const CustomPluginSlider = () => {
  tinymce.PluginManager.add('tomSlider', (editor) => {
    const openSlider = () => {
      const mediaFilters = [1]; // Image only

      // On Opening Popup
      editor.windowManager.open({
        title: 'Insert Slider',
        body: {
          type: 'panel',
          items: [
            {
              type: 'listbox',
              label: 'Slider Type:',
              name: 'ratio',
              items: [
                {
                  text: 'Landscape - Ratio 3 / 2',
                  value: 'landscape',
                },
                {
                  text: 'Portrait - Ratio 2 / 3',
                  value: 'portrait',
                },
              ],
            },
            {
              type: 'htmlpanel',
              html: '<div id="sliderList"></div>',
            },
          ],
        },
        buttons: [
          {
            type: 'cancel',
            text: 'Close',
          },
          {
            type: 'submit',
            text: 'Save',
            primary: true,
          },
        ],
        onSubmit: (api) => {
          const { ratio } = api.getData();

          // Filter checked
          const slides = [
            ...document.querySelectorAll('.si-SliderList_Item'),
          ].filter(
            (slide) => !!slide.querySelector('#sliderList .checkbox:checked'),
          );

          // Check if 2 or more slides are checked

          if (slides.length <= 1) {
            tinymce.activeEditor?.windowManager.alert(
              'Please select more than one image to insert',
            );
            return;
          }

          // Filter for captions
          const filteredSlides = slides.filter(
            (slide) =>
              slide.getElementsByTagName('textarea')[0].value.length !== 0,
          );

          // Check if slides have captions
          if (filteredSlides.length !== slides.length) {
            tinymce.activeEditor?.windowManager.alert(
              'Images require captions.',
            );
            return;
          }

          const result = filteredSlides.map((slide) => {
            const input = slide.querySelector('input');
            if (!input) {
              return false;
            }

            const attr = { id: input.dataset.id };
            const sl = input.dataset.slide;

            const caption = slide.getElementsByTagName('textarea')[0].value;

            return bbcodeTag(sl, caption.trim(), attr);
          });

          editor.insertContent(
            bbcodeTag('slider', result.join(''), { ratio: ratio }),
          );
          api.close();
        },
      });

      // Init Attachment list to include in Slider
      // Filtering Videos and Images

      const sliderListElement = document.querySelector('#sliderList');
      if (!sliderListElement) {
        return;
      }

      getAttachments()
        .filter(function (attachment: any) {
          return mediaFilters.indexOf(attachment.type) > -1;
        })
        .map(function (attachment: any) {
          var attachmentElement = document.createElement('div');
          attachmentElement.className = 'si-SliderList_Item';
          attachmentElement.innerHTML = [
            '<input type="checkbox" class="checkbox" data-src="',
            attachment.source_url,
            '" data-slide="slide"',
            ' data-id="',
            attachment.id,
            '"/><img style="max-width: 80px" class="thumb" src="',
            attachment.url,
            '"/><textarea maxlength="255" placeholder="Add caption here..." style="border: 1px solid #ccc;margin-left: 4px; width: 90%">',
            attachment.caption,
            '</textarea>',
          ].join('');
          sliderListElement.appendChild(attachmentElement);
          return attachmentElement;
        });
    };

    editor.ui.registry.addButton('tomSlider', {
      icon: 'slider',
      tooltip: 'Insert Slider',
      onAction: () => openSlider(),
    });
  });
};
