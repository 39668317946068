import { bbcodeTag } from '../helpers/bbcode_tag_creator';
import { ArticleAttachment } from 'lib/Model/Article';
import tinymce from 'tinymce';

function getAttachments(): ArticleAttachment[] {
  return ((window as any).tinymce_attachments as ArticleAttachment[]) ?? [];
}

export const CustomPluginCompareSlider = () => {
  tinymce.PluginManager.add('tomCompareSlider', (editor) => {
    const openCompareSlider = () => {
      const mediaFilters = [1]; // image only

      // On Opening Popup
      editor.windowManager.open({
        title: 'Insert image compare slider',
        body: {
          type: 'panel',
          items: [
            {
              type: 'listbox',
              label: 'Orientation mode:',
              name: 'orientation',
              items: [
                {
                  text: 'Horizontal',
                  value: 'horizontal',
                },
                {
                  text: 'Vertical',
                  value: 'vertical',
                },
              ],
            },
            {
              type: 'listbox',
              label: 'Show before / after labels:',
              name: 'showlabels',
              items: [
                {
                  text: 'Show',
                  value: 'true',
                },
                {
                  text: 'Hide',
                  value: 'false',
                },
              ],
            },
            {
              type: 'htmlpanel',
              html: '<div id="imageCompareSlider"></div>',
            },
          ],
        },
        buttons: [
          {
            type: 'cancel',
            text: 'Close',
          },
          {
            type: 'submit',
            text: 'Save',
            primary: true,
          },
        ],
        onSubmit: (api) => {
          const { orientation, showlabels } = api.getData();

          const compareSliderAttributes = {
            orientation,
            showlabels,
          };

          /**
           * Validation
           *
           */

          // Filter checked
          const slides = [...document.querySelectorAll('.item')].filter(
            (slide) =>
              !!slide.querySelector('#imageCompareSlider .checkbox:checked'),
          );

          // Check if 2 slides are checked
          if (slides.length !== 2) {
            tinymce.activeEditor?.windowManager.alert(
              'Kindly select two images for comparison.',
            );
            return;
          }

          // Filter for captions
          const filteredSlides = slides.filter(
            (slide) =>
              slide.getElementsByTagName('textarea')[0].value.length !== 0,
          );

          // Check if slides have captions
          if (filteredSlides.length !== 2) {
            tinymce.activeEditor?.windowManager.alert(
              'Images require captions.',
            );
            return;
          }

          /**
           * --------------------------------------------------------------------------
           */

          // Generate bbcode
          const result = filteredSlides.map((slide) => {
            const input = slide.querySelector('input');
            const lbl = slide.getElementsByTagName('textarea')[0].value;
            const label = lbl.trim();

            const compareImageAttributes = {
              id: input?.dataset?.id ?? '',
            };

            return bbcodeTag('compareimage', label, compareImageAttributes);
          });

          // Render result
          editor.insertContent(
            bbcodeTag(
              'compareslider',
              result.join(''),
              compareSliderAttributes,
            ),
          );
          api.close();
        },
      });

      const sliderListElement = document.querySelector('#imageCompareSlider');
      if (!sliderListElement) {
        return;
      }

      // Init Attachment list to include in Slider
      // Filtering Videos and Images
      getAttachments()
        .filter(function (attachment: any) {
          return mediaFilters.indexOf(attachment.type) > -1;
        })
        .map((attachment: any) => {
          var attachmentElement = document.createElement('div');
          attachmentElement.className = 'item';
          attachmentElement.innerHTML = [
            `<input type="checkbox" class="checkbox" data-src="${attachment.url}"`,
            `data-slide="compareslide${attachment.type}" data-id="${attachment.id}"/>`,
            `<img class="thumb" src="${attachment.url}" style="max-width: 80px"/>`,
            `<textarea maxlength="255" placeholder="Add caption" style="border: 1px solid #ccc;margin-left: 4px; width: 60%;">${attachment.caption}</textarea>`,
            `</div>`,
          ].join('');
          sliderListElement.appendChild(attachmentElement);
          return sliderListElement;
        });
    };

    editor.ui.registry.addButton('tomCompareSlider', {
      icon: 'gallery',
      tooltip: 'Insert compare slider',
      onAction: () => openCompareSlider(),
    });
  });
};
