import {
  Button,
  Card,
  CardContent,
  CardHeader,
  TextField,
  useTheme,
} from '@mui/material';
import { DelayedLinearProgress, SnackbarContext } from 'components';
import { Config } from 'config';
import { forgotPassword } from 'lib/Helper/Auth';
import * as React from 'react';

export const ForgotPassword: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const theme = useTheme();
  const snackbar = React.useContext(SnackbarContext);

  const [emailAddress, setEmailAddress] = React.useState('');

  return (
    <Card>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          setLoading(true);
          try {
            const userData = await forgotPassword(
              emailAddress,
              window.location.origin,
            );
            if (userData) {
              snackbar.success('Please check your email for a reset link.');
            }
          } catch (e: any) {
            if (e.response?.data?.message) {
              snackbar.error(e.response.data.message);
              return;
            }
            snackbar.error(e);
          } finally {
            setLoading(false);
          }
        }}
      >
        <CardHeader
          style={{ textAlign: 'center' }}
          title="Forgot password"
          subheader={`CMS ${Config.VERSION}`}
        />
        <CardContent>
          <TextField
            variant="outlined"
            placeholder="Email address"
            fullWidth
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value ?? '')}
            type="email"
            autoFocus
            required
            style={{ marginBottom: theme.spacing(1) }}
          />

          <Button
            type="submit"
            size="medium"
            color="primary"
            disabled={loading}
            variant="contained"
            className="login-btn"
            style={{
              paddingTop: theme.spacing(1),
              paddingBottom: theme.spacing(1),
              marginTop: theme.spacing(2),
              marginBottom: theme.spacing(2),
            }}
            fullWidth
          >
            Send me a reset code
          </Button>
          <Button variant="text" color="inherit" size="small" href="/login">
            Login
          </Button>
        </CardContent>

        <DelayedLinearProgress variant="query" loading={loading} />
      </form>
    </Card>
  );
};
