import { Typography } from '@mui/material';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import * as React from 'react';
import { StyledDivider, StyledWrapper } from './StyledComponents';

export const UnsupportedBrowser: React.FC = () => {
  return (
    <StyledWrapper>
      <SystemUpdateAltIcon color="primary" />

      <Typography color="inherit" align="center" component="div">
        <Typography variant="h5" gutterBottom>
          Your browser is not supported.
        </Typography>

        <StyledDivider />

        <Typography variant="body1" color="textSecondary">
          TimesOfMalta CMS works best on modern web browsers. Unfortunately it
          appears that your current browser does not support an important
          feature. If you are using an old browser, such as Internet Explorer or
          an out of date version of Chrome, Firefox or Safari, we kindly ask you
          to update to the latest version. If this problem persists, please
          submit a request to our support team using this
          <a
            href="https://support.talexio.com/hc/en-us/requests/new"
            target="_blank"
            rel="noreferrer noopener"
          >
            link
          </a>
        </Typography>
      </Typography>
    </StyledWrapper>
  );
};
