import { Config } from 'config';
import { bbcodeTag } from '../helpers/bbcode_tag_creator';
import tinymce from 'tinymce';

export const CustomPluginIFrame = () => {
  tinymce.PluginManager.add('tomIFrame', (editor, url) => {
    const openIFrame = () => {
      editor.windowManager.open({
        title: 'Embed IFrame',
        body: {
          type: 'panel',
          items: [
            {
              type: 'label',
              label: 'IFrame Snippet',
              items: [
                {
                  type: 'input',
                  name: 'iframe_html',
                  placeholder: '(Optional)',
                },
              ],
            },
            {
              type: 'label',
              label: 'AMP IFrame Snippet',
              items: [
                {
                  type: 'input',
                  name: 'iframe_amp',
                  placeholder: '(Optional)',
                },
              ],
            },
            {
              type: 'htmlpanel',
              html: '<div style="color:orangered">Do not use for social media embeds. Kindly use plugins.</div>',
            },
          ],
        },
        buttons: [
          {
            type: 'cancel',
            text: 'Close',
          },
          {
            type: 'submit',
            text: 'Save',
            primary: true,
          },
        ],
        onSubmit: (api) => {
          const data = api.getData();

          if (data.iframe_html.trim() === '' && data.iframe_amp.trim() === '') {
            tinymce.activeEditor?.windowManager.alert(
              'At least one entry is required.',
            );
          } else {
            // Loop through inputs
            const results = Object.entries(data).map((entry) => {
              // Sanitize entry
              entry[1] = entry[1].trim();

              if (entry[1].length) {
                // Create parent element to try create child html element
                const container = document.createElement('DIV');
                container.innerHTML = entry[1];

                if (
                  container &&
                  container.children[0] &&
                  container.children[0].attributes
                ) {
                  const attributes = container.children[0].attributes;

                  if (!attributes.length) {
                    tinymce.activeEditor?.windowManager.alert(
                      'Snippet does not include any attributes.',
                    );
                    return false;
                  }

                  // Check if IFRAME src contains blacklisted sources.
                  const isSRCblackListed = Object.values(attributes).some(
                    (attribute) => {
                      if (attribute.nodeName === 'src') {
                        return Config.IFRAME_BLACKLIST.some((el) =>
                          attribute.nodeValue?.includes(el),
                        );
                      }
                      return false;
                    },
                  );

                  if (isSRCblackListed) {
                    tinymce.activeEditor?.windowManager.alert(
                      'Kindly use plugin.',
                    );
                    return false;
                  }

                  const attr = Object.values(attributes).reduce(
                    (result: any, obj: any) => {
                      result[obj.nodeName] = obj.value;
                      return result;
                    },
                    {},
                  );

                  return bbcodeTag(`${entry[0]}_attributes`, '', attr);
                } else {
                  tinymce.activeEditor?.windowManager.alert(
                    'Snippet is not a valid html element.',
                  );
                }
              }
              return false;
            });

            const resultsEmpty = results.every(
              (result) => typeof result === 'undefined',
            );

            if (!resultsEmpty) {
              editor.insertContent(bbcodeTag('iframe', results.join('')));
              api.close();
            }
          }
        },
      });
    };

    editor.ui.registry.addButton('tomIFrame', {
      icon: 'iframe',
      tooltip: 'Embed IFrame',
      onAction: () => openIFrame(),
    });
  });
};
