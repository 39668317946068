import { EmojiFoodBeverage } from '@mui/icons-material';
import { Grow, Typography } from '@mui/material';
import { styled } from '@mui/system';
import * as React from 'react';

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  hideImage?: boolean;
}

const StyledWrapper = styled('div')(
  () => `
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    text-align: center;
`,
);

const StyledIcon = styled(EmojiFoodBeverage)(
  ({ theme }) => `
    font-size: 25pt;
    margin-bottom: ${theme.spacing(1)};
  `,
);

export const EmptyView: React.FC<Props> = ({
  hideImage = false,
  children,
  ...rest
}) => {
  return (
    <StyledWrapper {...rest}>
      {!hideImage && (
        <Grow in={true} timeout={500}>
          <StyledIcon />
        </Grow>
      )}

      <Typography
        style={{
          textAlign: 'center',
        }}
        component={typeof children === 'string' ? 'p' : 'div'}
      >
        {children || 'Nothing here yet.'}
      </Typography>
    </StyledWrapper>
  );
};
