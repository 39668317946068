import { bbcodeTag } from '../helpers/bbcode_tag_creator';
import tinymce from 'tinymce';

export const CustomPluginYoutube = () => {
  tinymce.PluginManager.add('tomYoutube', (editor, url) => {
    const openYouTube = () => {
      return editor.windowManager.open({
        title: 'Insert YouTube Video',
        body: {
          type: 'panel',
          items: [
            {
              type: 'input',
              name: 'video_url',
              label: 'URL:',
              placeholder: 'Format: https://youtu.be/dQw4w9WgXcQ',
            },
          ],
        },
        buttons: [
          {
            type: 'cancel',
            text: 'Close',
          },
          {
            type: 'submit',
            text: 'Save',
            primary: true,
          },
        ],
        onSubmit: (api) => {
          const { video_url } = api.getData();

          try {
            const trimmedUrl = new URL(video_url.trim());

            if (
              trimmedUrl.protocol === 'https:' &&
              trimmedUrl.hostname.indexOf('youtu') >= 0 &&
              trimmedUrl.href
            ) {
              editor.insertContent(
                bbcodeTag('video', '', {
                  type: 'youtube',
                  src: trimmedUrl.href,
                }),
              );
              api.close();
            } else {
              tinymce.activeEditor?.windowManager.alert('Invalid youtube URL');
            }
          } catch (ex) {
            tinymce.activeEditor?.windowManager.alert('Invalid URL');
          }
        },
      });
    };

    editor.ui.registry.addButton('tomYouTube', {
      icon: 'youtube',
      tooltip: 'Insert YouTube Video',
      onAction: () => openYouTube(),
    });
  });
};
