import * as React from 'react';
import {
  Avatar,
  AvatarGroup,
  Button,
  Card,
  CardHeader,
  IconButton,
  InputAdornment,
  Link,
  Skeleton,
  TextField,
  Toolbar,
  useTheme,
} from '@mui/material';
import {
  PageableTable,
  PageableTableRow,
  PopperDropdownMenu,
  SnackbarContext,
} from 'components';
import { PageableTableHeader } from 'components/PageableTable/components';
import { Search } from '@mui/icons-material';
import { styled } from '@mui/system';
import { generateSeededColour } from 'lib';
import { Role, RolesResponse } from 'lib/Model/Role';
import { deleteRole, fetchRoles } from 'lib/Service/Roles';
import { RoleDrawer } from './RoleDrawer';
import { useDebounce } from 'hooks';
import { AxiosError } from 'axios';
import { ConfirmDialog } from 'components/ConfirmDialog';

const StyledLink = styled(Link)(
  ({ theme }) => `
    margin-bottom: 0;
    text-transform: none;
    font-weight: 500;
    text-decoration: none;
    font-size: 120%;
    cursor: pointer;
  `,
);

export const RolesTable: React.FC = () => {
  const theme = useTheme();
  const snackbar = React.useContext(SnackbarContext);

  const [textSearch, setTextSearch] = React.useState('');
  const [selectedRole, setSelectedRole] = React.useState<Role | undefined>();
  const [toDelete, setToDelete] = React.useState<Role | undefined>();

  const [pageNumber, setPageNumber] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [queryResponse, setQueryResponse] = React.useState<
    RolesResponse | undefined
  >();

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const debouncedTextSearch = useDebounce(textSearch, 500);
  const fetchData = React.useCallback(() => {
    setLoading(true);
    fetchRoles(debouncedTextSearch, pageNumber + 1, pageSize)
      .then((d) => {
        setLoading(false);
        setQueryResponse(d);
      })
      .catch((e) => {
        setLoading(false);
        snackbar.error(e);
      });
  }, [
    setLoading,
    setQueryResponse,
    pageNumber,
    pageSize,
    debouncedTextSearch,
    snackbar,
  ]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = React.useMemo(() => {
    return [
      {
        key: 'avatar',
        label: '',
        sortable: false,
        props: {
          style: { width: 60 },
        },
      },
      {
        key: 'role',
        label: 'Role',
        sortable: true,
      },
      {
        key: 'members',
        label: '',
        sortable: true,
        props: {
          style: {
            width: 200,
          },
        },
      },
      {
        key: 'permissions',
        label: '',
        sortable: true,
      },
      {
        key: 'actions',
        label: '',
        sortable: true,
      },
    ] as PageableTableHeader[];
  }, []);

  const [rows, setRows] = React.useState<PageableTableRow[]>([]);

  React.useEffect(
    () =>
      setRows(
        queryResponse?.data?.map((r) => {
          return {
            key: `role_${r.id}`,
            cells: [
              {
                key: 'avatar',
                display: (
                  <Avatar
                    style={{
                      backgroundColor: `#${generateSeededColour({
                        seed: r.name,
                      })}`,
                    }}
                  >
                    &nbsp;
                  </Avatar>
                ),
              },
              {
                key: 'role',
                display: (
                  <StyledLink onClick={() => setSelectedRole(r)}>
                    {r.name}
                  </StyledLink>
                ),
              },
              {
                key: 'members',
                display: (
                  <AvatarGroup max={4}>
                    {/* r.users.map((u) => (
                      // TODO: receive users from api?
                      <Avatar src={u.photoUrl} />
                    )) */}
                  </AvatarGroup>
                ),
              },
              {
                key: 'permissions',
                display: (
                  <React.Fragment>
                    {r.permissions?.length ?? 0} permissions
                  </React.Fragment>
                ),
              },
              {
                key: 'actions',
                props: {
                  style: {
                    textAlign: 'right',
                  },
                },
                display: (
                  <PopperDropdownMenu
                    menuItems={[
                      {
                        label: 'Edit',
                        onClick: () => setSelectedRole(r),
                      },
                      {
                        label: 'Delete',
                        onClick: () => {
                          setToDelete(r);
                        },
                      },
                    ]}
                  />
                ),
              },
            ],
          } as PageableTableRow;
        }) ?? [],
      ),
    [queryResponse, setRows],
  );

  return (
    <Card>
      <CardHeader
        title="Roles"
        action={
          <Button
            color="primary"
            onClick={() => {
              setSelectedRole(undefined);
              setDrawerOpen(true);
            }}
          >
            Add role
          </Button>
        }
      />
      <Toolbar style={{ marginBottom: theme.spacing(1) }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Type here to find roles.."
          value={textSearch}
          onChange={(e) => setTextSearch(e.target.value ?? '')}
          autoFocus
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>

      {loading ? (
        <Skeleton variant="rectangular" height={150} />
      ) : (
        <PageableTable
          columns={columns}
          rows={rows}
          pageNumber={pageNumber}
          rowCount={queryResponse?.total_results ?? rows.length}
          onChangePageSize={setPageSize}
          onChangePage={setPageNumber}
        />
      )}

      <RoleDrawer
        open={Boolean(selectedRole) || drawerOpen}
        onClose={() => {
          setSelectedRole(undefined);
          setDrawerOpen(false);
        }}
        role={selectedRole}
        onSaved={() => {
          setSelectedRole(undefined);
          setDrawerOpen(false);
          fetchData();
        }}
      />

      {toDelete && (
        <ConfirmDialog
          dialogProps={{
            open: true,
          }}
          typePrompt={`${toDelete?.name}`}
          title="Are you sure you want to proceed?"
          yesText="Delete"
          noText="Cancel"
          onCancel={() => setToDelete(undefined)}
          onConfirm={async () => {
            try {
              await deleteRole(toDelete);
              snackbar.success('User deleted');
              fetchData();
            } catch (error) {
              if (error instanceof AxiosError && error.response?.data.message) {
                snackbar.error(error.response.data.message);
                return;
              }
              snackbar.error('Unknown error while saving');
            } finally {
              setToDelete(undefined);
            }
          }}
        />
      )}
    </Card>
  );
};
