import { Typography } from '@mui/material';
import { Alert } from '@mui/lab';
import * as React from 'react';
import { ErrorStack } from './ErrorStack';

interface Props {
  error: Error;
  showStack?: boolean;
  useAlertContainer?: boolean;
}

export const ErrorMessage: React.FC<Props> = ({
  error,
  showStack = false,
  useAlertContainer = false,
}) => {
  const message = (
    <React.Fragment>
      <Typography color="error" component="span">
        {error.message}
      </Typography>

      {showStack && error.stack && <ErrorStack stack={error.stack} />}
    </React.Fragment>
  );

  if (useAlertContainer) {
    return <Alert severity="error">{message}</Alert>;
  }

  return message;
};
