import currency from 'currency.js';

/**
 * Turns "myPascalCase" to "My Pascal Case"
 * @param input The string in pascal case
 */
export function pascalToWords(input: string): string {
  return (
    input
      // Split when an upper case letter follows a lower case letter
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      // Set first letter of input to upper case
      .replace(/^./, (c) => c.toUpperCase())
  );
}

/**
 * Turns "my_underscored_words" to "My Underscored Words"
 * @param input The string in underscored format
 */
export function underscoredToWords(input: string): string {
  const frags = input.split('_');
  for (let i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
}

/**
 * Capitalises strings
 * @param input the string to capitalise
 * @param eachWord flag to capitalize each word in string
 */
export function capitalise(input: string, eachWord?: boolean): string {
  const lowercase = input.toLowerCase();

  if (eachWord) {
    return lowercase
      .split(' ')
      .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
      .join(' ');
  }

  return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce<T extends (...args: any) => any>(
  fn: T,
  wait: number,
  immediate?: boolean,
): T {
  let timeout: number | undefined;

  // @ts-ignore
  return (...args: Parameters<T>): ReturnType<T> => {
    const callNow = immediate && !timeout;

    window.clearTimeout(timeout);
    timeout = window.setTimeout(later, wait);

    if (callNow) {
      fn(...args);
    }

    function later() {
      timeout = undefined;

      if (!immediate) {
        fn(...args);
      }
    }
  };
}

/**
 * Formats a value as a monetary value
 */
export function toMonetary(input: currency.Any, options?: currency.Options) {
  // Hide decimal part if .00
  const precision = currency(input).cents() === 0 ? 0 : 2;

  return currency(input, {
    errorOnInvalid: true,
    formatWithSymbol: true,
    symbol: options?.symbol ?? '\u20AC', // EUR
    precision,

    ...options,
  }).format();
}

export function isFunction(fn: any): fn is Function {
  return typeof fn === 'function';
}

export const weeksInAYear = 52;

/**
 * truncate
 * @param string
 * @return string
 */
export const truncate = (text: string, cutAt: number = 35) =>
  text.length > cutAt ? `${text.substring(0, cutAt)}...` : text;

/**
 * checkUrl
 * @param string
 * @return boolean
 */
export const checkUrl = (url: any) => {
  var res = url.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g,
  );
  return res !== null;
};

/**
 * isEmptyOrSpaces
 * @param string
 * @return boolean
 */
export const isEmptyOrSpaces = (str: any) => {
  return str === null || str.match(/^ *$/) !== null;
};
