import { bbcodeTag } from '../helpers/bbcode_tag_creator';
import tinymce from 'tinymce';

export const CustomPluginTikTok = () => {
  tinymce.PluginManager.add('tomTikTok', (editor, url) => {
    const openTikTok = () => {
      return editor.windowManager.open({
        title: 'Embed TikTok',
        body: {
          type: 'panel',
          items: [
            {
              type: 'input',
              name: 'url',
              placeholder: 'TikTok URL',
            },
          ],
        },
        buttons: [
          {
            type: 'cancel',
            text: 'Close',
          },
          {
            type: 'submit',
            text: 'Save',
            primary: true,
          },
        ],
        onSubmit: (api) => {
          const { url } = api.getData();

          try {
            const trimmedUrl = new URL(url.trim());
            if (!trimmedUrl || !trimmedUrl.href) {
              return;
            }

            const splitUrl = trimmedUrl.href.split('?').shift();
            if (!splitUrl) {
              return;
            }

            editor.insertContent(
              bbcodeTag('tiktok', '', {
                url: splitUrl.trim(),
              }),
            );
          } catch (ex) {
            tinymce.activeEditor?.windowManager.alert('Invalid URL');
          }

          api.close();
        },
      });
    };
    editor.ui.registry.addButton('tomTikTok', {
      icon: 'tiktok',
      tooltip: 'Embed TikTok post',
      onAction: () => openTikTok(),
    });
  });
};
