import { getApiClient } from 'lib/Helper/Api';
import { Tag, TagsResponse } from 'lib/Model/Tag';
import QueryString from 'qs';

export function fetchTags(
  search: string,
  pageNumber: number,
  itemsPerPage: number,
): Promise<TagsResponse> {
  return getApiClient()
    .get(`/api/tags`, {
      params: {
        page: pageNumber,
        per_page: itemsPerPage,
        query: search,
      },
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((d) => {
      return {
        ...d.data,
        data: d.data?.data,
      };
    });
}

export function fetchTag(id: number): Promise<Tag> {
  return getApiClient().get(`/api/tags/${id}`, {
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export function createTag(tag: Tag): Promise<Tag> {
  return getApiClient().post(`/api/tags`, QueryString.stringify(tag), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

export function updateTag(tag: Tag): Promise<Tag> {
  return getApiClient().put(`/api/tags/${tag.id}`, QueryString.stringify(tag), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

export function deleteTag(tag: Tag): Promise<Tag> {
  return getApiClient()
    .delete(`/api/tags/${tag.id}`, {
      method: 'DELETE',
      responseType: 'json',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    })
    .then((d) => {
      return d.data?.data;
    });
}
