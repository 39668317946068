import * as React from 'react';
import { Breadcrumbs as DynamicBreadcrumbs } from 'react-breadcrumbs-dynamic';
import { BreadcrumbItem } from './BreadcrumbItem';

export const Breadcrumbs: React.FC = (props) => {
  return (
    <div>
      <DynamicBreadcrumbs separator=" / " item={BreadcrumbItem} />
    </div>
  );
};
