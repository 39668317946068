import { parseISO } from 'date-fns';
import { getApiClient } from 'lib/Helper/Api';
import { Author, AuthorsResponse } from 'lib/Model/Author';
import QueryString from 'qs';

export function fetchAuthors(
  search: string,
  pageNumber: number,
  itemsPerPage: number,
  activeOnly?: boolean,
  includeNonActiveIds?: number[],
): Promise<AuthorsResponse> {
  return getApiClient()
    .get(`/api/authors`, {
      params: {
        page: pageNumber,
        per_page: itemsPerPage,
        query: search,
        active_only: activeOnly,
        non_active_ids: includeNonActiveIds,
      },
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((d) => {
      const authors = d.data?.data.map((u: any) => hydrateAuthor(u));
      return {
        ...d.data,
        data: authors,
      };
    });
}

export function fetchAuthor(id: number): Promise<Author> {
  return getApiClient()
    .get(`/api/authors/${id}`, {
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((d) => hydrateAuthor(d.data?.data));
}

export function createAuthor(author: Author): Promise<Author> {
  return getApiClient()
    .post(`/api/authors`, QueryString.stringify(author), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then((d) => hydrateAuthor(d.data?.data));
}

export function updateAuthor(author: Author): Promise<Author> {
  return getApiClient()
    .put(`/api/authors/${author.id}`, QueryString.stringify(author), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then((d) => hydrateAuthor(d.data?.data));
}

export function deleteAuthor(author: Author): Promise<Author> {
  return getApiClient()
    .delete(`/api/authors/${author.id}`, {
      method: 'DELETE',
      responseType: 'json',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    })
    .then((d) => {
      return d.data?.data;
    });
}

function hydrateAuthor(data: any): Author {
  return {
    ...data,
    date: data.date ? parseISO(data.date) : undefined,
    visible: data.visible ? 1 : 0,
    active: data.active ? 1 : 0,
  };
}
