/**
 * Generates a colour seeded from a string.
 *
 * @param seed The seed string
 * @param mixPasses How many times to mix white into the colour
 */
export function generateSeededColour({
  seed,
  mixPasses = 0,
}: {
  seed: string;
  mixPasses?: number;
}) {
  const hashCode = seed
    .split('')
    // Reversing order so that "sequential" names like Shift A/B/C are sure to generate different colours
    // Less likely to have the reverse, eg: A Shift, B Shift, C Shift
    .reverse()
    .reduce((hash, char) => char.charCodeAt(0) + ((hash << 5) - hash), 0);

  let colourHex = hashCode & 0xffffff;

  if (mixPasses > 0) {
    colourHex = [16, 8, 0].reduce(
      (hex, shift) =>
        (hex += mixColour((colourHex >> shift) & 0xff, mixPasses) << shift),
      0,
    );
  }

  return hexToRGB(colourHex);
}

/**
 * Generates a random colour. By default, generates soft pastel colours
 *
 * @param min Minimum colour byte
 * @param max Maximum colour byte
 * @param mixPasses How many times to mix white into the colour
 */
export function generateRandomColour({
  min = 0,
  max = 255,
  mixPasses = 0,
}: {
  min?: number;
  max?: number;
  mixPasses?: number;
} = {}) {
  const colourHex = [16, 8, 0].reduce(
    (hex, shift) => (hex += getRandomColourByte(min, max, mixPasses) << shift),
    0,
  );

  return hexToRGB(colourHex);
}

function getRandomColourByte(min: number, max: number, mixPasses: number) {
  let colourByte = getRandomInt(min, max);

  if (mixPasses > 0) {
    mixColour(colourByte, mixPasses);
  }

  return colourByte;
}

/**
 * Mixing with white lightens the colour. Every pass creates a lighter colour.
 *
 * @param colourByte The colour byte to mix white with
 * @param mixPasses Number of passes
 */
function mixColour(colourByte: number, mixPasses: number) {
  return (colourByte + 255 * mixPasses) / (mixPasses + 1);
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random#Getting_a_random_integer_between_two_values_inclusive
function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function hexToRGB(hex: number) {
  const hexString = hex.toString(16).toUpperCase();

  // Pad with zeros if necessary
  return '00000'.substring(0, 6 - hexString.length) + hexString;
}
